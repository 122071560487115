import { types } from '../../actions/types';

const initialState = {
    title: '',
};

const getHeaderTitle = (state) => {
    const path = window.location.pathname;
    if (
        path.lastIndexOf('home') !== -1 ||
        path.lastIndexOf('construction') !== -1
    ) {
        return {
            ...state,
            title: 'NISAC'
        }
    }
    else if (path.lastIndexOf('explorer') !== -1) {
        return {
            ...state,
            title: 'EXPLORER'
        }
    }
    else if (path.lastIndexOf('catalog') !== -1) {
        return {
            ...state,
            title: 'CATALOG'
        }
    }
    else if (path.lastIndexOf('dambreak') !== -1 || path.lastIndexOf('riverine') !== -1) {
        return {
            ...state,
            title: 'WELL'
        }
    }
    else if (path.lastIndexOf('rswam') !== -1) {
        return {
            ...state,
            title: 'rSWAM'
        }
    }
    else {
        return {
            ...state,
            title: ''
        }
    }
};

const appState = (state = initialState, action) => {
    switch (action.type) {
        // case types.GET_APP:
        //     return action.payload;
        case types.SET_APP_TITLE:
            return getHeaderTitle(state);
        default:
            return state;
    }
};

export default appState;
