export const types = {
	// App
	SET_APP_TITLE: 'setAppTitle',

	// Dambreak Page
	GET_SLIDERS: 'getSliders',
	GET_DAMS: 'getDams',
	GET_DAM_POINTS: 'getDamPoints',
	GET_DAM_AGG: 'getDamAgg',
	GET_DAM_STATS: 'getDamStats',
	GET_VIEWPORT: 'getViewport',
	GET_LAYERS: 'getLayers',
	GET_GEOJSON: 'getGeoJson',
	GET_RUN: 'getRun',
	UPDATE_LAYER_GEOJSON: 'updateLayerGeojson',
	UPDATE_HEIGHTS: 'updateHeights',
	UPDATE_STORAGE: 'updateStorage',
	UPDATE_VIEWPORT: 'updateViewport',
	UPDATE_HEATMAP: 'updateHeatmap',
	UPDATE_MAP: 'updateMap',
	UPDATE_SELECTED_DAM: 'updateSelectedDam',
	UPDATE_SEARCH_TEXT: 'updateSearchText',
	UPDATE_RUN: 'updateRun',
	UPDATE_SLIDER_VALS: 'updateSliderVals',
	UPDATE_DAM_STRUCTURES: 'updateDamStructures',
	UPDATE_DAM_POPUP: 'updateDamPopup',

	// Catalog Page
	GET_MODELS: 'getModels',
	GET_MODEL_TYPES: 'getModelTypes',
	GET_DAM_RESOURCES: 'getDamResources',
	
	// Explorer Page
	GET_EXPLORER_MODELTYPES: 'getModelTypes',
	GET_EXPLORER_VISIBILITY: 'getVisibility',
	GET_EXPLORER_RESOURCES: 'getResources',
	GET_EXPLORER_GEOJSON: 'getExplorerGeoJson',
	GET_EXPLORER_MODELGEOS: 'getExplorerModelGeos',
	UPDATE_EXPLORER_VIEWPORT: 'updateExplorerViewport',
	UPDATE_EXPLORER_MAP: 'updateExplorerMap',
	UPDATE_EXPLORER_POINT: 'updateExplorerPoint',
	UPDATE_EXPLORER_TEXT: 'updateExplorerText',
	UPDATE_EXPLORER_VISIBILITY: 'updateVisibility',
	UPDATE_EXPLORER_POPUP: 'updateExplorerPopup',

	// Model Run Page
	UPDATE_MODELRUN_VIEWPORT: 'updateModelRunViewport',
	UPDATE_MODELRUN_MAP: 'updateModelRunMap',
	GET_MODELRUN_INFO: 'getModelRunInfo',
	UPDATE_MODELRUN_MODEL: 'updateModelRunModel',
	RECEIVE_COMBINED_RESULTS: 'receiveCombinedResults',
	RECEIVE_GEOJSON: 'receiveGeoJson',
	REQUEST_COMBINED_RESULTS: 'requestCombinedResults',
	SET_MAP_CONFIG: 'setMapConfig',
	MAP_INIT: 'mapInit',
	GET_MODELRUN_FILES: 'getModelrunFiles',
	GET_METADATA: 'getMetadata',
	CLEAR_METADATA: 'clearMetadata',

	// rSWAM Page
	GET_RSWAM_VIEWPORT: 'getRSwamViewport',
	GET_RSWAM_HEATMAP: 'getRSwamHeatmap',
	GET_RSWAM_POINTS: 'getRSwamPoints',
	GET_RSWAM_CITIES: 'getRSWamCities',
	UPDATE_RSWAM_VIEWPORT: 'updateRSwamViewport',
	UPDATE_RSWAM_MAP: 'updateRSwamMap',
	UPDATE_RSWAM_TEXT: 'updateRSwamText',
	UPDATE_RSWAM_POPUP: 'updateRSwamPopup',
	UPDATE_RSWAM_POINT: 'updateRSwamPoint',

	// rSWAM Run Page
	GET_RSWAM_RUN_VIEWPORT: 'getRSwamRunViewport',
	GET_RSWAM_RUN_GEOJSON: 'getRSwamRunGeojson',
	GET_RSWAM_RUN_TREATMENT: 'getRSwamRunTreatment',
	UPDATE_RSWAM_RUN_VIEWPORT: 'updateRSwamRunViewport',
	UPDATE_RSWAM_RUN_MAP: 'updateRSwamRunMap',
	UPDATE_RSWAM_RUN_CITYBOUNDS: 'updateRSwamRunCityBounds',
	UPDATE_RSWAM_RUN_OPER: 'updateRSwamRunOper',
	UPDATE_RSWAM_RUN_SC: 'updateRSwamRunSC',

	// rSWAM Result Page
	GET_RSWAM_RESULT_VIEWPORT: 'getRSwamResultViewport',
	GET_RSWAM_RESULT_CONTINGENCIES: 'getRSwamResultContingencies',
	GET_RSWAM_RESULT_GEOJSON: 'getRSwamResultGeojson',
	GET_RSWAM_RESULT_TREATMENT: 'getRSwamResultTreatment',
	UPDATE_RSWAM_RESULT_VIEWPORT: 'updateRSwamResultViewport',
	UPDATE_RSWAM_RESULT_MAP: 'updateRSwamResultMap',
	UPDATE_RSWAM_RESULT_CITYBOUNDS: 'updateRSwamResultCityBounds',

	// Others
	GET_TEST: 'getTest',
	GET_MODELTYPE: 'getModelType',
	GET_SIMULATIONS: 'getSimulations',
	GET_POSTS: 'getPosts',
	GET_APP: 'getApp',
	GET_ES_QUERY: 'getEsQuery',
};