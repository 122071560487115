import React, { Component } from 'react';
import Header from './components/header';
import { connect } from 'react-redux';
import { setAppTitle } from './actions';
import './App.scss';
import { Route, Switch, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import HomePage from './pages/home';
import ExplorerPage from './pages/explorer-page';
import DambreakPage from './pages/dambreak-page';
import CatalogPage from './pages/catalog-page';
import ModelRunPage from './pages/modelrun-page';
import ConstructionPage from './pages/construction-page';
import RSwamPage from './pages/rswam-page';
import RSwamRunPage from './pages/rswam-run-page';
import RSwamResultPage from './pages/rswam-result-page';
import HistoryListener from './HistoryListener';

const initialState = {
  hideBtn: false,
  mapbox: {},
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    };
    this.fetch = this.fetch.bind(this);
  }

  // componentDidMount() {
  //   this.props.setAppTitle();
  // }

  fetch() {
    this.exampleMethod_updatesState();
  }

  exampleMethod_updatesState() {
    const { hideBtn } = this.state;
    this.setState({
      hideBtn: !hideBtn
    });
  }

  exampleMethod_returnsAValue(number) {
    return number + 1;
  }

  render() {

    return (
      <div className="App" data-test="appComponent">
        <BrowserRouter>
          <HistoryListener>
            <Header title={this.props.appState.title} />
            <Switch>
              <Redirect exact from="/" to="/home" />
              <Route path="/home" component={HomePage} onEnter={this.props.setHeaderTitle} />
              <Route path="/explorer" component={ExplorerPage} onEnter={this.props.setHeaderTitle} />
              <Route path="/catalog" component={CatalogPage} />
              <Route path="/dambreak" component={DambreakPage} />
              <Route path="/construction" component={ConstructionPage} />
              <Route path="/rswam" component={RSwamPage} />
              <Route path="/rswam-run" component={RSwamRunPage} />
              <Route path="/rswam-result" component={RSwamResultPage} />
              <Route path="/model/:modelType" component={ModelRunPage} />
              <Route path="*" component={HomePage} />
            </Switch>
          </HistoryListener>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appState: state.appState,
    posts: state.posts
  }
}

const dispatchToProps = (dispatch, props) => ({
  setAppTitle: () => dispatch(setAppTitle()),
});

export default connect(mapStateToProps, dispatchToProps)(App);
