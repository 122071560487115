import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExploreIcon from '@material-ui/icons/Explore';
import MapIcon from '@material-ui/icons/Map';
import LandscapeIcon from '@material-ui/icons/Landscape';
import OpacityIcon from '@material-ui/icons/Opacity';
import HomeIcon from '@material-ui/icons/Home';
import { withRouter } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { authContext } from '../../adalConfig';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const TemporaryDrawer = (props) => {
	const classes = useStyles();
	const [state, setState] = React.useState({
		left: false,
	});

	const toggleDrawer = (side, open) => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, [side]: open });
	};

	const iconFinder = (text) => {
		if (text === 'Catalog') {
			return <MapIcon />;
		} else if (text === 'Explorer') {
			return <ExploreIcon />;
		} else if (text === 'Dam Break') {
			return <LandscapeIcon />;
		} else if (text === 'rSWAM') {
			return <OpacityIcon />;
		} else if (text === 'Sign Out') {
			return <ExitToAppIcon />;
		} else {
			return <HomeIcon />;
		}
	}

	const reroute = (event, text) => {
		let route = '';
		if (text === 'Catalog') {
			route = '/catalog';
		} else if (text === 'Explorer') {
			route = '/explorer';
		} else if (text === 'Dam Break') {
			route = '/dambreak';
		} else if (text === 'rSWAM') {
			route = '/rswam';
		} else if (text === 'Sign Out') {
			authContext.logOut();
		} else {
			route = '/home';
		}
		props.history.push(route);
	}

	const sideList = side => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(side, false)}
			onKeyDown={toggleDrawer(side, false)}
		>
			<List>
				{['Home', 'Explorer', 'Catalog', 'Dam Break', 'rSWAM', 'Sign Out'].map((text, index) => (
					<ListItem button key={text} onClick={(e) => reroute(e, text)}>
					<ListItemIcon>{iconFinder(text)}</ListItemIcon>
					<ListItemText primary={text} />
					</ListItem>
				))}
			</List>
		</div>
	);

	return (
		<div>
			<IconButton edge="start" onClick={toggleDrawer('left', true)} className={classes.menuButton} color="inherit" aria-label="menu">
				<MenuIcon />
			</IconButton>
			<Drawer
				open={state.left} 
				anchor="left"
				onClose={toggleDrawer('left', false)}>
				{sideList('left')}
			</Drawer>
		</div>
	);
}

const mapStateToProps = state => { return { ...state } };
const dispatchToProps = (dispatch, props) => ({
	dispatch
});

export default compose( withRouter, connect(mapStateToProps, dispatchToProps))(TemporaryDrawer);
