import appconfig from './index';

const MAX_ZOOM_LEVEL = 9;

export const heatmapLayer = {
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    // 'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
	'heatmap-weight': {
		property: "count",
		type: "exponential",
		stops: [
			[0, 1],
			[6, 1],
		]
	},
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    // 'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
	  'heatmap-intensity': {
		stops: [
			[0, 0.65],
			[9, 1],
		]
	  },
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.2,
      '#67a9cf',
      0.4,
      '#d1e5f0',
      0.6,
      '#fddbc7',
      0.8,
      '#ef8a62',
      0.9,
      '#26ccb0'
    ],
    // Adjust the heatmap radius by zoom level
    // 'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
    'heatmap-radius': {
		stops: [
			[0, 10],
			[9, 20],
		]
	  },
    // Transition from heatmap to circle layer by zoom level
	'heatmap-opacity': {
		default: 0.4,	
		stops: [
			[0, 0.6],
			[7, 0.5],
			[11, 0.0],
		]
	}
  }
};

export const heatmapLayers = [
	{
		modelType: "Dam Break",
		pointColor: "#b2182b",
		paint: {
			// Increase the heatmap weight based on frequency and property magnitude
			// 'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
			'heatmap-weight': {
				property: "count",
				type: "exponential",
				stops: [
					[0, 1],
					[6, 1],
				]
			},
			// Increase the heatmap color weight weight by zoom level
			// heatmap-intensity is a multiplier on top of heatmap-weight
			// 'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
				'heatmap-intensity': {
				stops: [
					[0, 0.65],
					[9, 1],
				]
				},
			// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
			// Begin color ramp at 0-stop with a 0-transparancy color
			// to create a blur-like effect.
			'heatmap-color': [
				'interpolate',
				['linear'],
				['heatmap-density'],
				0,
				'rgba(33,102,172,0)',
				0.2,
				'#cbcd71',
				0.4,
				'#cdbd4a',
				0.6,
				'#cd4e00',
				0.8,
				'#c72500',
				0.9,
				'#a11723'
			],
			// Adjust the heatmap radius by zoom level
			// 'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
			'heatmap-radius': {
				stops: [
					[0, 10],
					[9, 20],
				]
			},
			// Transition from heatmap to circle layer by zoom level
			'heatmap-opacity': {
				default: 0.4,	
				stops: [
					[0, 0.6],
					[7, 0.5],
					[11, 0.0],
				]
			}
		}
	},
	{
		modelType: "Riverine Model",
		pointColor: "#26ccb0",
		paint: {
			// Increase the heatmap weight based on frequency and property magnitude
			// 'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
			'heatmap-weight': {
				property: "count",
				type: "exponential",
				stops: [
					[0, 1],
					[6, 1],
				]
			},
			// Increase the heatmap color weight weight by zoom level
			// heatmap-intensity is a multiplier on top of heatmap-weight
			// 'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
			'heatmap-intensity': {
				stops: [
					[0, 0.65],
					[9, 1],
				]
			},
			// Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
			// Begin color ramp at 0-stop with a 0-transparancy color
			// to create a blur-like effect.
			'heatmap-color': [
			'interpolate',
			['linear'],
			['heatmap-density'],
			0,
			'rgba(33,102,172,0)',
			0.2,
			'#67a9cf',
			0.4,
			'#d1e5f0',
			0.6,
			'#fddbc7',
			0.8,
			'#ef8a62',
			0.9,
			'#26ccb0'
			],
			// Adjust the heatmap radius by zoom level
			// 'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
			'heatmap-radius': {
				stops: [
					[0, 10],
					[9, 20],
				]
			},
			// Transition from heatmap to circle layer by zoom level
			'heatmap-opacity': {
				default: 0.4,	
				stops: [
					[0, 0.6],
					[7, 0.5],
					[11, 0.0],
				]
			}
		}
	},
	{
		modelType: "Global Flu",
		pointColor: "#868080",
	},
	{
		modelType: "pSWAM",
		pointColor: "#88529d",
	},
	{
		modelType: "Storm Surge",
		pointColor: "#26aa20",
	}
];
  
export const mapStyles = {
	light: 'mapbox://styles/mapbox/light-v10',
}

export const viewport = { 
	style: 'mapbox://styles/mapbox/light-v10',
	containerStyle: {
		width: '100vw',
		height: '100vh',
	},
	center: [-98.583, 39.833],
	zoom: [3],
	accessToken: appconfig.MAPBOX_TOKEN,
};

export const baseLayers = [
	{
		style: "mapbox://styles/mapbox/light-v9",
		label: "light",
		staticUrl:
			"https://api.mapbox.com/styles/v1/" +
			"mapbox/light-v9/static/-95.079777,35.362741,0.80,0.00,0.00" +
			"/200x200?access_token=" +
			appconfig.MAPBOX_TOKEN
	},
	{
		style: "mapbox://styles/mapbox/satellite-v9",
		label: "satellite",
		staticUrl:
			"https://api.mapbox.com/styles/v1/" +
			"mapbox/satellite-v9/static/-95.079777,35.362741,0.80,0.00,0.00" +
			"/200x200?access_token=" +
			appconfig.MAPBOX_TOKEN
	},
	{
		style: "mapbox://styles/mapbox/outdoors-v10",
		label: "outdoor",
		staticUrl:
			"https://api.mapbox.com/styles/v1/" +
			"mapbox/outdoors-v10/static/-95.079777,35.362741,0.80,0.00,0.00" +
			"/200x200?access_token=" +
			appconfig.MAPBOX_TOKEN
	}
];
