import { types } from './types';
import axios from 'axios';
import appconfig from '../constants';
import {
	boolQuery,
	requestBodySearch,
	ValueCountAggregation
} from 'elastic-builder';
import {
	getEsQuery2,
	getEsQuery3,
	generateBoolQueryModelList
} from '../utils';

const processResults = (res, type, dispatch) => {
	switch(type) {
		case 'modeltypes':
			return dispatch({
				type: types.GET_MODELTYPES,
				payload: {
					modelTypes: res
				}
			});
		case 'models':
			return dispatch({
				type: types.GET_MODELS,
				payload: {
					rowsPerPage: res.rowsPerPage,
					count: res.data.aggregations.model_cnt.value,
					models: res.data.hits.hits
				}
			});
        case 'metadata':
			return dispatch({
				type: types.GET_MODEL_METADATA,
				payload: res
			});
		default:
			return dispatch({
				type: types.GET_ES_QUERY,
				payload: res.data
			});
	}
};

// TODO: DELETE THIS
// export const getMetadata = () => async(dispatch) => {
// 	let queryBody = requestBodySearch()
// 		.source(['model_file_storage']);

// 	await getMetadataFile(queryBody).then(res => {
// 		processResults(res.data.aggregations.model_type.buckets, 'modeltypes', dispatch)
// 	})
// 	.catch(err => {
// 		console.log(err);
// 	});
// };

export const getModelTypes = () => async(dispatch) => {
	let queryBody = requestBodySearch()
		.source(['model_type']);

	await getEsQuery2(queryBody).then(res => {
		processResults(res.data.aggregations.model_type.buckets, 'modeltypes', dispatch)
	})
	.catch(err => {
		console.log(err);
	});
};

export const getModelsByText = (searchText, size, pageNum, modelType) => async(dispatch) => {
	const vals = new ValueCountAggregation('model_cnt', '_id');
	const queryObject = {
		modelType: modelType,
		searchText: searchText
	};
	if (modelType === "Dam Break") {
		queryObject.modelType = undefined;
	} else if (modelType === "Riverine Model") {
		queryObject.modelType = 'Riverine';
	}

	let queryBody = requestBodySearch()
		.aggs([vals])
		.query(boolQuery().must(generateBoolQueryModelList(queryObject)));

	await getEsQuery3(modelType, queryBody, size).then(res => {
		processResults(res, 'models', dispatch);
	})
	.catch(err => {
		console.log(err);
	})
};

// export const getDamsByText = (searchText, size, pageNum, modelType) => async(dispatch) => {
// 	const vals = new ValueCountAggregation('model_cnt', '_id');
// 	const queryObject = {
// 		modelType: modelType,
// 		searchText: searchText
// 	};
// 	let queryBody = requestBodySearch()
// 		.aggs([vals])
// 		.query(boolQuery().must(generateBoolQueryModelList(queryObject)));

// 	await getEsQuery3(queryBody, size).then(res => {
// 		processResults(res, 'models', dispatch);
// 	})
// 	.catch(err => {
// 		console.log(err);
// 	})
// };

export const getCatalogQuery = (query, size=0) => {
	const host = appconfig.ES_HOST + appconfig.ES_RUN_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};