import { types } from './../../actions/types';
import appconfig from './../../constants';

const initialState = {
	viewport: { 
		style: "mapbox://styles/mapbox/light-v9",
		mapboxAccessToken: appconfig.MAPBOX_TOKEN,
		width: '100vw',
		height: '100vh',
		latitude: 37.8,
		longitude: -96,
		zoom: 3.5,
	},
	layers: [ {} ],
    geojson: { features: [], type: "FeatureCollection" },
    modelPoints: { features: [], type: "FeatureCollection" },
    modelGeos: [ {modelType: "", heatmap: {features: [], type: "FeatureCollection"}, points: {features: [], type: "FeatureCollection"}}],
    map: { },
    modelTypes: [],
    resources: [],
    point: {lat: 37.8, lng: -96, },
    searchText: "",
    visibility: [{modelType: "", visible: false}],
    popup: { visible: false, coordinates: [-96.0, 37.8], modelType: "Type", model: "Model" }
}

export default (state=initialState , action) => {
    switch(action.type){
        case types.GET_EXPLORER_MODELTYPES:
			const sortedModelTypes = action.payload.modelTypes.sort((a, b) => {
                return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
              }).map(m => {
				return m.key;
			});
            return {
                ...state,
                modelTypes: sortedModelTypes,
            };
        case types.GET_EXPLORER_VISIBILITY:
            return {
                ...state,
                visibility: action.payload,
            }
        case types.GET_EXPLORER_RESOURCES:
            return {
                ...state,
                resources: action.payload.sort((a,b) => {
                    return a.modelType.toLowerCase().localeCompare(b.modelType.toLowerCase());
                })
            }
        case types.GET_EXPLORER_GEOJSON:
            return {
                ...state,
                geojson: action.payload.geojson,
                modelPoints: action.payload.points,
            }
        case types.GET_EXPLORER_MODELGEOS:
            return {
                ...state,
                modelGeos: action.payload,
            }
		case types.UPDATE_EXPLORER_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom
				},
			}
		case types.UPDATE_EXPLORER_MAP:
			return {
				...state,
				map: action.payload
            }
        case types.UPDATE_EXPLORER_POINT:
            return {
                ...state,
                point: action.payload
            }
        case types.UPDATE_EXPLORER_TEXT:
            return {
                ...state,
                searchText: action.payload
            }
        case types.UPDATE_EXPLORER_VISIBILITY:
						const vis = state.visibility.map(v => {
						if (v.modelType === action.payload.modelType) {
						v.visible = action.payload.visible;
						}
						return v;
						});
            return {
                ...state,
                visibility: vis
            }
        case types.UPDATE_EXPLORER_POPUP:
            return {
                ...state,
                popup: {
                    visible: action.payload.visible,
                    coordinates: action.payload.coordinates,
                    modelType: action.payload.modelType,
                    model: action.payload.model,
                }
            }
        default:
            return state;
    }
};
