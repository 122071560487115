import axios from 'axios';
import Geohash from 'latlon-geohash';
import appconfig from '../constants';
import {
	geoBoundingBoxQuery,
	queryStringQuery,
	rangeQuery,
	sort,
	requestBodySearch,
	boolQuery,
	matchQuery,
} from 'elastic-builder';
import { apim_key } from '../constants';
import { modelList, otherList } from '../constants/sources';
import apiAxios from '../interceptors/subscription-header';

export const generateNearestSort = (query) => {
	if (query) {
		const latlon = [query.nearestPoint.lng, query.nearestPoint.lat];
		if (latlon.length === 2) {
			return sort('geohash', 'asc')
				.geoDistance(latlon)
				.unit('mi')
				.mode('min')
				.distanceType('arc');
		}
	}
	return sort('nid', 'desc');
}

const validateLatValue = (lat) => {
	lat = lat <= 90 ? lat : 90;
	lat = lat >= -90 ? lat : -90;
	return lat;
};

const validateLonValue = (lon) => {
	lon = lon <= 180 ? lon : 180;
	lon = lon >= -180 ? lon : -180;
	return lon;
};

export const generateBoolQueryModelList = (queryObject) => {
	let boolQueryList = [];
	if (queryObject.modelType) {
		queryObject.modelType === 'Riverine' ?
			boolQueryList.push(matchQuery('model_type_name', queryObject.modelType)) :
			boolQueryList.push(matchQuery('model_type', queryObject.modelType));
		if(queryObject.modelType === 'Riverine') {
			boolQueryList = _addRiverineBool(boolQueryList, queryObject);
		}
	}
	if (queryObject.text) {
		boolQueryList.push(queryStringQuery("*" + queryObject.text + "*"));
	}
	return boolQueryList;
};

const _addRiverineBool = (boolQueryList, queryObject) => {
	if (queryObject.modelType === 'Riverine') {
		boolQueryList.push(
			matchQuery('file_format', 'geojson')
		);
	}
	return boolQueryList;
}
const _updateBoolQueryModelType = (boolQueryList, queryObject) => {
	if (queryObject.modelType) {
		boolQueryList.push(
			(queryObject.modelType !== 'Riverine Model') ?
				matchQuery('model_type', queryObject.modelType) :
				matchQuery('model_type_name', 'Riverine')
		);
		_addRiverineBool(boolQueryList, queryObject);
	}
	return boolQueryList;
};

const _updateBoolQueryTag = (boolQueryList, queryObject) => {
	if (queryObject.tag) {
		if (queryObject.tag.length > 0) {
			boolQueryList.push(matchQuery('tag', queryObject.tag));
		}
	}
	return boolQueryList;
};

const _updateBoolQuerySearchText = (boolQueryList, queryObject) => {
	if (queryObject.text.length > 1) {
		const queryStringVal = queryObject.text
			.split(" ")
			.join(" AND ");
		if (typeof queryObject.textField !== 'undefined') {
			boolQueryList.push(
				queryStringQuery("*" + queryStringVal + "*").defaultField(queryObject.textField)
			);
		} else {
			boolQueryList.push(
				queryStringQuery("*" + queryStringVal + "*")
			);
		}
	}
	return boolQueryList;
};

const _updateBoolQueryBounds = (boolQueryList, queryObject) => {
	if (queryObject.bounds) {
		const qry = geoBoundingBoxQuery("geohash")
			.top(validateLatValue(queryObject.bounds._ne.lat))
			.right(validateLonValue(queryObject.bounds._ne.lng))
			.bottom(validateLatValue(queryObject.bounds._sw.lat))
			.left(validateLonValue(queryObject.bounds._sw.lng));
		boolQueryList.push(qry);
	}
	return boolQueryList;
};

const _updateBoolQueryMinHeight = (boolQueryList, queryObject) => {
	if (queryObject.minHeight >= 0) {
		boolQueryList.push(
			rangeQuery("nid_height").gte(queryObject.minHeight)
		);
	}
	return boolQueryList;
};

const _updateBoolQueryMaxHeight = (boolQueryList, queryObject) => {
	if (queryObject.maxHeight >= 0) {
		boolQueryList.push(
			rangeQuery("nid_height").lte(queryObject.maxHeight)
		);
	}
	return boolQueryList;
};

const _updateBoolQueryMinStorage = (boolQueryList, queryObject) => {
	if (queryObject.minStorage >= 0) {
		boolQueryList.push(
			rangeQuery("nid_storage").gte(queryObject.minStorage)
		);
	}
	return boolQueryList;
};

const _updateBoolQueryMaxStorage = (boolQueryList, queryObject) => {
	if (queryObject.maxStorage >= 0) {
		boolQueryList.push(
			rangeQuery("nid_storage").lte(queryObject.maxStorage)
		);
	}
	return boolQueryList;
};

const _updateBoolQueryNoRange = (boolQueryList, queryObject) => {
	if (!queryObject.noRange) {
		boolQueryList.push(rangeQuery("file_year").gte(2016));
	}
	return boolQueryList;
};

export const generateBoolQueryList = (queryObject) => {
	let boolQueryList = [];
	if (queryObject) {
		boolQueryList = _updateBoolQueryModelType(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryTag(boolQueryList, queryObject);
		boolQueryList = _updateBoolQuerySearchText(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryBounds(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryMinHeight(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryMaxHeight(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryMinStorage(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryMaxStorage(boolQueryList, queryObject);
		boolQueryList = _updateBoolQueryNoRange(boolQueryList, queryObject);
	}
	return boolQueryList;
};

export const generateHeatmapGeojson = (res) => {
	const featureValues = res.map((e) => {
		let ll = e.key;
		if (ll == null) {
			ll = {
				lat: null,
				lon: null
			};
		} else {
			ll = Geohash.decode(ll);
		}

		const obj = {
			type: "Feature",
			properties: {
				count: e.doc_count
			},
			geometry: {
				coordinates: [parseFloat(ll.lon), parseFloat(ll.lat)],
				type: "Point"
			}
		};
		return obj;
	});
	return {
		type: "FeatureCollection",
		features: featureValues
	};
};

// TODO: This could use some cleanup, way duplicative and serving too many callers
export const cleanElasticResponse = (res) => {
	let featureValues = res.map((e) => {
		// console.log(e);
		var geohash = '';
		if (e._source['geohash'] instanceof Array) {
			geohash = e._source['geohash'][0];
		} else {
			geohash = e._source['geohash'];
		}

		if (e._source['tag'] == null) {
			e._source['tag'] = [null];
		}

		if (e._source['tag'] == null) {
			e._source['tag'] = [null];
		}

		var ll = Geohash.decode(geohash);

		let locTitle = e._source['title'];
		let locType = e._source['model_type'];
		let locNid = e._source['nid'];
		let locOrch = e._source['orchestration_id'];
		if (e._index === 'model-runs') {
			locTitle = e._source['model_run_name'];
			locType = e._source['model_type_name'];
			locNid = e._source['model_run_id'];
			locOrch = e._source['model_file_name'];
		}
		if (typeof locTitle === 'undefined') {
			if (typeof e._source.breach_height_percentage === 'undefined') {
				locTitle = e._source.dam_name;
			} else {
				locTitle = e._source.dam_name + " (" + e._source.breach_height_percentage + ")";
			}
			locType = "Dam Break";
			locNid = e._source.nidid;
		}
		let locCity = '';
		let locState = '';
		let locRegion = '';
		if (e._index === 'rswam-cities') {
			locCity = e._source['city'];
			locState = e._source['state_abbr'];
			locNid = e._source['geohash'];
			locType = 'rSWAM City';
		}
		if (e._index === 'treatment-plants') {
			locCity = e._source['City'];
			locState = e._source['StateAbbr'];
			locRegion = e._source['Region'];
			locTitle = e._source['Name'];
		};

		let obj = {
			type: 'Feature',
			properties: {
				title: locTitle,
				model_type: locType,
				nodeid: locNid,
				orchid: locOrch,
				city: locCity,
				state: locState,
				region: locRegion,
				operational: true,
				count: 1
			},
			geometry: {
				coordinates: [parseFloat(ll['lon']), parseFloat(ll['lat'])],
				type: 'Point'
			}
		};
		return obj;
	});
	return {
		type: 'FeatureCollection',
		features: featureValues
	};
}

export const getEsQuery = (query, size = 0) => {
	const host = appconfig.ES_HOST + appconfig.ES_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getEsQuery2 = (query, size = 0) => {
	const host = appconfig.ES_HOST + appconfig.ES_RUN_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getEsQueryRswam = (query, size = 0) => {
	const host = appconfig.ES_HOST + appconfig.ES_RSWAM_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getEsQueryTreat = (query, size = 0) => {
	const host = appconfig.ES_HOST + appconfig.ES_TREAT_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getEsQueryResult = (query, size = 0) => {
	const host = appconfig.ES_HOST + appconfig.ES_RESULT_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get( url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getModelRunFiles = modelRunId => {
	const host = appconfig.ES_HOST + appconfig.ES_MODEL_INDEX;
	const url = host + `/_search?size=10`;
	const query = requestBodySearch()
		.query(matchQuery('model_run_id', modelRunId));
	
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getEsQuery3 = (modelType, query, size = 0) => {
	let host = appconfig.ES_HOST + appconfig.ES_RUN_INDEX;
	if (modelType === "Dam Break") host = appconfig.ES_HOST + appconfig.ES_DAM_INDEX;
	if (modelType === "Riverine Model") host = appconfig.ES_HOST + appconfig.ES_MODEL_INDEX;
	const url = host + `/_search?size=${size}`;
	return (
		axios.get(url, {
			params: {
				source: JSON.stringify(query),
				source_content_type: 'application/json'
			},
			auth: appconfig.ES_CREDS,
		})
	);
};

export const getMultiSearchQuery = (queryObject) => {
	let queries = generateBoolQueryList(queryObject);
	let sortObject = generateNearestSort(queryObject);
	const source = (queryObject.modelType === 'Riverine Model') ?
		modelList : otherList;
	let queryBody = requestBodySearch()
		.source(source)
		.query(boolQuery().must(queries))
		.sort(sortObject);
	return queryBody;
};

export const fetchRunById = (nodeid) => {
	const url = "https://nisac.azure-api.net/drupal-data/get-data";
	const header = { "Ocp-Apim-Subscription-Key": apim_key };
	return (
		axios.post(
			url,
			{
				nid: nodeid
			},
			{
				headers: header
			}
		)
	);
};

export const getStateCityQuery = (state, city) => {
	let boolQueryList = [];
	boolQueryList.push(queryStringQuery("'" + state + "'").defaultField("StateAbbr"));
	boolQueryList.push(queryStringQuery(city).defaultField("City"));
	return boolQueryList;
};

// export const getRelatedRuns = (nididOrGage) => {
// 	let boolQueryList = [];
// 	boolQueryList.push(queryStringQuery(nididOrGage));
// 	const queryBody = requestBodySearch()
// 			.query(boolQuery().must(boolQueryList))
// 			.sort(sort('_score', 'desc'));
// 	console.log('--body--', queryBody.toJSON());

// 	return from(<Promise<any>>this._client
// 			.search({
// 					index: config[ELASTIC_RELATED_RUNS_INDEX],
// 					body: queryBody.toJSON(),
// 					size: 10
// 			})
// 			.then(body => {
// 					// return Promise.resolve(body.hits.hits);
// 					const hits = body.hits.hits;
// 					// console.log('--hits--', hits);
// 					return Promise.resolve(
// 							this.create_clean_related_runs_list(hits)
// 					);
// 			}));
// }

export const launchRSwam = (rswamParam) => {
	const url = "https://nisac.azure-api.net/SimwareAPI/config/upload"
	return (
		apiAxios.post(
			url,
			rswamParam
		)
	);
};