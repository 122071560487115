import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ModelList from '../model-list';

const useStyles = makeStyles({
  root: {
    minWidth: 300,
	maxWidth: 300,
    position: 'absolute',
    zIndex: '1000',
    marginTop: '20px',
    marginLeft: '20px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  divide: {
    margin: '20px 0',
  },
  search: {
    width: '90%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  results: {
	width: 'inherit',
  },
  selectLabel: {
	paddingTop: '8px',
	fontSize: '8px',
  },
  select: {
    width: '250px',
  },
  submit: {
	width: 'inherit',
	fontSize: 13,
	color: "#0000C0",
	fontStyle: "italic",
  },
  sortLabel: {
	margin: 0,
	fontSize: '12px'
  },
});

export const modelTypes = {
	"Dam Break": "dambreak",
	"Global Flu": "flu",
	"pSWAM": "pswam",
	"Riverine Model": "riverine",
	"Storm Surge": "surge",
}

const ExplorerCard = (props) => {

	const classes = useStyles();

	const [searchText, setSearchText] = React.useState(props.explorerState.searchText);
	const updateText = (event) => {
		setSearchText(event.target.value);
	}

	const onModelSelect = (modelType, model) => {
		if (modelType === "Dam Break" || modelType === "Riverine Model") {
			props.history.push("/model/" + modelTypes[modelType] + "?id=" + model.orchestra);
		} else {
			// props.history.push("/model/" + modelTypes[modelType] + "?id=" + model.nid)
			props.history.push("/construction")
		}
	}

	const onKeyPress = (event) => {
		if (event.keyCode === 13) {
			props.onModelSearch(event);	// Trigger when user presses Enter
		}
	}

	return (
		<Card className={classes.root}>
			<CardContent>
				<Grid container direction="row" justify="center">
					<TextField 
						className={classes.search} 
						id="searchbar" 
						value={searchText}
						onChange={updateText}
						onBlur={props.onModelSearch}
						onKeyDown={onKeyPress}
						label="Search"/>
				</Grid>
				<Divider className={classes.divide} variant='middle' />
				<Typography variant="h6" component="h6">
					Simulations
				</Typography>
				<p className={classes.sortLabel}>
					Sorted by: distance
				</p>
				{
					props.explorerState.resources.map((resource, idx) => {
						return(<ModelList 
							key={idx} 
							{...resource} 
							onModelSelect={onModelSelect}
							onModelExpand={props.onModelExpand}
							backgroundColor={props.getColor(resource.modelType)}
						 />);
					})
				}
			</CardContent>
			<CardActions className={classes.cardActions}>
			</CardActions>
		</Card>
	);
}

const mapStateToProps = state => { return { 
	appState: state.appState,
	explorerState: state.explorerState,
}};

const dispatchToProps = (dispatch, props) => ({
});

export default connect(mapStateToProps, dispatchToProps)(ExplorerCard);
