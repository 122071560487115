import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {
    injectComponents
} from 'kepler.gl/components';
import {
    reorderLayer, layerVisConfigChange, addCustomMapStyle, inputMapStyle, layerConfigChange,
    updateMap, forwardTo, toggleSidePanel
} from 'kepler.gl/actions';
import { addDataToMap } from 'kepler.gl/actions';
import queryString from 'query-string';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

// Local Javascript Libraries
import pnnlConfig from '../config/default';
import {
    fetchCombinedResults,
    fetchModelRunGeoJson,
    getModelFiles,
    fetchMetadata,
    clearMetadata,
} from '../actions';
import { satelliteStyle } from '../components/satellite-style';
import ModelRunCard from '../components/modelrun-card';

const API = pnnlConfig.API_URI;

const KeplerGl = injectComponents([]);

const styles = theme => ({
	root: {
		height: '100vh',
		paddingLeft: '24px !important',
		paddingRight: '24px !important',
    },
    progresstext: {
        backgroundColor: '#ebc100'
    }
});

class ModelRunPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }
    isRightDisabled = true;
    isLeftDisabled = true;
    areMultiple = false;
    API_URL = '';
    activeLayer = (this.props.history.location.pathname.includes('dambreak')) ?
        'arrival' : 'depth';

    componentWillUnmount() {
        this.props.clearMetadata();
    }

    componentDidMount() {
        if (!localStorage.getItem('damUrl') || localStorage.getItem('damUrl') === '') {
            localStorage.setItem('damurl', this.props.location.search);
        }
        this.API_URL = API + "create/rift/results/";
        const values = queryString.parse(localStorage.getItem('damurl'))

        if (Object.entries(values).length !== 0 && ('id' in values)) {
            const orchIds = values.id;
            this.orchestrationId = orchIds.split(',');
            if (this.orchestrationId.length > 1) {
                this.areMultiple = true;
                this.isRightDisabled = false;
                this.orchestrationId = this.orchestrationId[0];
            } else {
                this.orchestrationId = this.orchestrationId[0];
            }

            if (!this.props.history.location.pathname.includes('dambreak')) {
                const modelRunId = new URLSearchParams(this.props.history.location.search).get('id');
                this.props.getModelFiles(modelRunId)
                    .then(res => {
                        this.API_URL = API + "BlobDownloader/BlobDownloader";
                        const geojsonFile = this.props.modelRunState.modelFiles
                            .filter(f => f._source.file_format === 'geojson')[0];
                        const metadataFile = this.props.modelRunState.modelFiles
                            .filter(f => f._source.file_format === 'json')[0];
                        this._loadBlob(this.API_URL, geojsonFile._source.model_file_storage);
                        const data = {
                            'blobUrl': metadataFile._source.model_file_storage
                        };
                        this.props.fetchMetadata(this.API_URL, data)
                            .then(metadata => { })
                            .catch(err => console.log('Failed to get Metadata: ' + err));
                    })
                    .catch(err => console.log(err));
            } else {
                this._loadCombinedResults(this.API_URL + this.orchestrationId);
            }
        }
        this.props.toggleSidePanel(null);
        this.props.inputMapStyle(satelliteStyle);
        this.props.addCustomMapStyle();
    }

    _reorderLayers = () => {
        const newOrder = [];
        const layers = [];
        this.props.keplerGl.beltramiMap.visState.layers.forEach((e, i) => {
            let layer = {};
            let cnt = 2;
            // TODO: these need to use the function I created below
            // TODO: the filter string should come from the output from the action
            if (e.config.label.indexOf('Dam Site Selection') !== -1) {
                layer = {
                    'oldIndex': i,
                    'newIndex': 0
                }
            } else if (e.config.label.indexOf('Local Dem Tile') !== -1) {
                layer = {
                    'oldIndex': i,
                    'newIndex': 1
                }
            } else {
                layer = {
                    'oldIndex': i,
                    'newIndex': cnt
                }
                cnt += 1;
            }
            layers.push(layer);
        });
        layers.sort((a, b) => a.newIndex - b.newIndex);
        layers.forEach(l => newOrder.push(l.oldIndex));
        this.props.reorderLayer(newOrder);
        this.activeLayer = 'arrival';
    };

    _loadBlob = async (url, blobUrl) => {
        const data = {
            'blobUrl': blobUrl
        };
        const fetchObj = {
            'id': 'test',
            'label': 'Depth Geojson Data',
            'url': url,
        }
        this.props.fetchModelRunGeoJson(fetchObj, data)
            .then(res => {
                this.props.addDataToMap(this.props.modelRunState.dataset).then(() => {
                });
                this._reorderLayers();
                this._initializeLayers();
                this._toggleArrival();
            })
            .catch(err => console.log(err));
    };

    _loadCombinedResults = async (url) => {
        let cnt = 0;
        this.props.fetchCombinedResults(url)
            .then(() => {
                if (Object.entries(this.props.modelRunState.geojsonResults).length !== 0) {
                    this.props.modelRunState.geojsonResults.forEach((geojson) => {
                        this.props.fetchModelRunGeoJson(geojson, undefined)
                            .then(x => {
                                this.props.addDataToMap(this.props.modelRunState.dataset).then(() => {
                                    cnt += 1;
                                })
                                if (cnt === 3) {
                                    this._reorderLayers();
                                    this._initializeLayers();
                                    this._toggleArrival();
                                }
                            });
                    })
                }
            }).catch(err => console.log(err));
    };

    _popupVisible = () => {
        const layers = this.props.keplerGl.beltramiMap.visState.layers.filter(x => x.config.label === 'Dam Site Selection' || x.config.label === 'Local Dem Tile');
        layers.forEach(lay => {
            let layerConfig = lay.config;
            layerConfig.isVisible = !layerConfig.isVisible;
            this.props.dispatch(layerVisConfigChange(lay, layerConfig));
        })
    }

    _rightClick = () => {
        const orch = this.props.match.params.orchestration;
        const orchestrations = orch.split(',');
        this.orchestrationId = orchestrations[1];
        if (orchestrations.indexOf(this.orchestrationId) === orchestrations.length - 1) {
            this.isRightDisabled = true;
            this.isLeftDisabled = false;
        }
    }

    _leftClick = () => {
        // TODO: These should probably be added to state at the beginning
        const orch = this.props.match.params.orchestration;
        const orchestrations = orch.split(',');
        // TODO: This needs to be passed in NEED a model to get the orchestrations available
        this.orchestrationId = orchestrations[0];
        if (orchestrations.indexOf(this.orchestrationId) === 0) {
            this.isRightDisabled = false;
            this.isLeftDisabled = true;
        }
    }

    // Returns a layer from the kepler object based on a label
    // TODO: robustness update this should be id, need to figure out how to return id
    _layerFinder = (layers, label) => {
        let layer = {};
        layer = layers.filter(l => l.config.label === label);
        return layer[0];
    }

    _toggleLayersStroke(layers) {
        layers.forEach(layer => layer.config.visConfig.stroked = !layer.config.visConfig.stroked);
    }

    _generateColorField = (name, index, type) => {
        return {
            format: "",
            id: name,
            name: name,
            tableFieldIndex: index,
            type: type
        }
    }

    _getArrayFromGjsonField = (dataToFeature, field) => {
        const field_array = [];
        dataToFeature.forEach(df => field_array.push(df.properties[field]));
        return field_array;
    }

    _createVisConfig = (colors, cat, name, type) => {
        return {
            category: cat,
            colors: colors,
            name: name,
            type: type
        }
    }

    _toggleStrokes = (arr_layer, depth_layer, dem_layer) => {
        if (arr_layer) {
            this._toggleLayersStroke([arr_layer, depth_layer, dem_layer]);
        } else {
            this._toggleLayersStroke([depth_layer]);
        }
    }

    _changeArrivalColor = (arr_layer) => {
        const new_arr_layer = arr_layer;
        new_arr_layer.config.colorField = this._generateColorField("arrival_mi", 2, "integer");
        new_arr_layer.config.colorDomain = this._getArrayFromGjsonField(arr_layer.dataToFeature, 'arrival_mi')
            .sort((a, b) => { return a - b });
        new_arr_layer.config.visConfig.colorRange = this._createVisConfig(
            ['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850'], 'ColorBrewer', 'ColorBrewer RdYlGn-6', 'diverging'
        );
        this.props.layerConfigChange(arr_layer, new_arr_layer.config);
    }

    _changeDepthColor = (depth_layer) => {
        const new_depth_layer = depth_layer;
        new_depth_layer.config.colorField = this._generateColorField("depth_ft", 2, "integer");
        new_depth_layer.config.colorDomain = this._getArrayFromGjsonField(depth_layer.dataToFeature, 'depth_ft')
            .sort((a, b) => { return a - b });
        new_depth_layer.config.visConfig.colorRange = this._createVisConfig(
            ['#f1eef6', '#d0d1e6', '#a6bddb', '#74a9cf', '#2b8cbe', '#045a8d'], 'ColorBrewer', 'ColorBrewer PuBu-6', 'sequential'
        );
        this.props.layerConfigChange(depth_layer, new_depth_layer.config);
    }

    _changeDemColor = (dem_layer) => {
        const new_dem_layer = dem_layer;
        new_dem_layer.config.colorField = this._generateColorField("height_feet", 3, "real");
        new_dem_layer.config.colorDomain = this._getArrayFromGjsonField(dem_layer.dataToFeature, 'height_feet')
            .sort((a, b) => { return a - b });
        new_dem_layer.config.visConfig.colorRange = this._createVisConfig(
            ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#636363', '#252525'], 'ColorBrewer', 'ColorBrewer Greys-6', 'singlehue'
        );
        this.props.layerConfigChange(dem_layer, new_dem_layer.config);
    }

    _updateMapZoom = () => {
        const mapState = this.props.keplerGl.beltramiMap.mapState;
        const newZoom = mapState.zoom - 1;
        this.props.keplerGlDispatch(updateMap({ zoom: newZoom }));
    }

    _initializeLayers = () => {
        const layers = this.props.keplerGl.beltramiMap.visState.layers;
        const arr_layer = this._layerFinder(layers, 'Arrival Geojson Data');
        const depth_layer = this._layerFinder(layers, 'Depth Geojson Data');
        const dem_layer = this._layerFinder(layers, 'Local Dem Tile');

        // Hide on init per Dave J. Comments (11/22/19)
        if (dem_layer) {
            dem_layer.config.isVisible = false;
        }

        this._toggleStrokes(arr_layer, depth_layer, dem_layer);
        // TODO: Should these be combined?
        if (arr_layer) {
            this._changeArrivalColor(arr_layer);
        }
        this._changeDepthColor(depth_layer);
        if (dem_layer) {
            this._changeDemColor(dem_layer);
        }

        this._updateMapZoom();
    }

    _toggleArrival = () => {
        const arr_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Arrival Geojson Data');
        const depth_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Depth Geojson Data');
        if (typeof arr_layer !== "undefined" && typeof depth_layer !== "undefined") {
            arr_layer.config.isVisible = true;
            depth_layer.config.isVisible = false;
            arr_layer.config.visConfig.opacity = 0.3;
            this.props.layerConfigChange(arr_layer, arr_layer.config);
            this.props.layerConfigChange(depth_layer, depth_layer.config);
        }
        this.setState({loading: false});
    }

    _toggleDepth = () => {
        const arr_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Arrival Geojson Data');
        const depth_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Depth Geojson Data');
        if (typeof arr_layer !== "undefined" && typeof depth_layer !== "undefined") {
            arr_layer.config.isVisible = false;
            depth_layer.config.isVisible = true;
            depth_layer.config.visConfig.opacity = 0.3;
            this.props.layerConfigChange(arr_layer, arr_layer.config);
            this.props.layerConfigChange(depth_layer, depth_layer.config);
        }
    }

    _handleLayers = (newLayer) => {
        if (newLayer === 'arrival') {
            if (this.activeLayer !== 'arrival') {
                this.activeLayer = 'arrival';
                this._toggleArrival();
            }
        } else {
            if (this.activeLayer !== 'depth') {
                this.activeLayer = 'depth';
                this._toggleDepth();
            }
        }
    }

    _getUrlPath = () => {
        let filterName = "Local Dem Tile";
        if (this.activeLayer === 'arrival') {
            filterName = "Arrival Geojson Data";
        } else if (this.activeLayer === 'depth') {
            filterName = "Depth Geojson Data";
        }
        const geojson = this.props.modelRunState.geojsonResults.filter((r) => r.label === filterName);
        if (geojson !== null) return geojson[0].url;
        return "";
    }

    render() {
        const { classes } = this.props;

        return (
            <div style={{ position: 'inherit', width: '100%', height: '96%' }}>
                {this.state.loading ?
                    <div>
                        <LinearProgress />
                        <Typography variant="body2" className={classes.progresstext}>This may take a minute or more to load...</Typography>
                    </div> : null}
                <AutoSizer>
                    {({ height, width }) => (
                        <KeplerGl
                            mapboxApiAccessToken={pnnlConfig.MAPBOX}
                            id="beltramiMap"
                            width={width}
                            height={height}
                        />
                    )}
                </AutoSizer>
                <ModelRunCard {...this} style={{ marginBottom: '10px' }}></ModelRunCard>
            </div>
        );
    }
}

const mapStateToProps = state => { return { ...state } };
const dispatchToProps = (dispatch, props) => ({
    fetchCombinedResults: (url) => dispatch(fetchCombinedResults(url)),
    getModelFiles: (modelRunId) => dispatch(getModelFiles(modelRunId)),
    fetchMetadata: (url, data) => dispatch(fetchMetadata(url, data)),
    clearMetadata: () => dispatch(clearMetadata()),
    fetchModelRunGeoJson: (fetchObj, data) => dispatch(fetchModelRunGeoJson(fetchObj, data)),
    toggleSidePanel: (val) => dispatch(toggleSidePanel(val)),
    inputMapStyle: (satelliteStyle) => dispatch(inputMapStyle(satelliteStyle)),
    addCustomMapStyle: () => dispatch(addCustomMapStyle()),
    addDataToMap: (data) => dispatch(addDataToMap(data)),
    reorderLayer: (data) => dispatch(reorderLayer(data)),
    layerConfigChange: (lyr, config) => dispatch(layerConfigChange(lyr, config)),
    keplerGlDispatch: forwardTo("beltramiMap", dispatch)
});

// export default connect(mapStateToProps, dispatchToProps)(ModelRunPage);
export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(ModelRunPage);