import { combineReducers } from 'redux';
import appState from './appState/reducer';
import catalogState from './catalogState/reducer';
import dambreakState from './dambreakState/reducer';
import explorerState from './explorerState/reducer';
import modelRunState from './modelRunState/reducer';
import rswamState from './rswamState/reducer';
import rswamRunState from './rswamRunState/reducer';
import rswamResultState from './rswamResultState/reducer';
import simulations from './simulations/reducer';
import keplerGlReducer from 'kepler.gl/reducers';

const customizedKeplerGlReducer = keplerGlReducer
  .initialState({
    uiState: {
      // hide side panel when mounted
      activeSidePanel: 'layer',
      // hide all modals whtn mounted
      currentModal: null
    }
  });

export default combineReducers({
	keplerGl: customizedKeplerGlReducer,
	appState,
  catalogState,
	dambreakState,
	explorerState,
	modelRunState,
	rswamState,
	rswamRunState,
	rswamResultState,
	simulations,
});
