import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import styled from 'styled-components';
import { theme } from 'kepler.gl/styles';
import MetadataTable from '../metadata-table';

const useStyles = makeStyles({
	heading: {
		color: "#0040C0",
		fontSize: "17px",
		textAlign: "center",
	},
});

const StyledMapConfigDisplay = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 10px;
  right: 10px;
  background-color: #FFFFFF; //${theme.sidePanelBg};
  font-size: 11px;
  width: 350px;
  color: #202020; //${theme.textColor};
  word-wrap: break-word;
  min-height: 160px;
  padding: 10px;
`;

const ScenarioBtn = styled.button`
	float: right;
	color: #A0A7B4;
	background: none;
	border: none;
	height: 20px;
	&:hover {
		background: #535a62;
	}
	&:disabled {
		background: #242730;
		color: #6A7485;
	}
`;

const ModelRunCard = (page) => {

	const classes = useStyles();

	const maxContent = 1;
	const [content, setContent] = React.useState(0);
	const contentHeads = ["Metadata", "Download"];

	const contentLeft = (event) => {
		setContent(content > 0 ? content - 1 : maxContent);
	}

	const contentRight = (event) => {
		setContent(content < maxContent ? content + 1 : 0);
	}

	const downloadFiles = (event) => {
		const url = page._getUrlPath();
		if (url !== "") {
			window.location.assign(url);
		}
	}

	const getMetadataTitle = () => {
		let title = '';
		if (page.props.modelRunState.metadata.nidInput &&
			page.props.history.location.pathname.includes('dambreak')) {
			title = page.props.modelRunState.metadata['nidInput']['nidid'] + ' - ' + page.props.modelRunState.metadata['nidInput']['damName'];
		} else {
			title = page.props.modelRunState.metadata['gageName'] + ' (' +
				page.props.modelRunState.metadata['returnPeriod'] + ')';
		}
		return title;
	}

	return (
		<StyledMapConfigDisplay>
			{page.props.modelRunState.metadata && Object.keys(page.props.modelRunState.metadata).length !== 0 ?
				<div style={{ paddingBottom: '25px' }}>
					<Grid container direction="row" spacing={1}>
						<Grid item sm={6} xs={12}>
							{/* <h3>{page.props.modelRunState.metadata['nidInput']['nidid']} - {page.props.modelRunState.metadata['nidInput']['damName']}</h3> */}
							<h3>{getMetadataTitle()}</h3>
						</Grid>
						{(page.props.history.location.pathname.includes('dambreak')) ?
							<Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
								<ButtonGroup size="small">
									<Button
										className={page.activeLayer === 'arrival' ? 'active' : ''}
										onClick={page._handleLayers.bind(page, 'arrival')}
									>
										Arrival
								</Button>
									<Button
										className={page.activeLayer === 'depth' ? 'active' : ''}
										onClick={page._handleLayers.bind(page, 'depth')}
									>
										Depth
								</Button>
								</ButtonGroup>
								<div>
									{page.areMultiple ?
										<span>
											<ScenarioBtn key="1" disabled={page.isRightDisabled} onClick={page._rightClick}>
												<ChevronLeft />
											</ScenarioBtn>
											<ScenarioBtn key="2" disabled={page.isLeftDisabled} onClick={page._leftClick}>
												<ChevronRight />
											</ScenarioBtn>
										</span> : null
									}
								</div>
							</Grid>
							: null}
						<Grid container direction="row">
							<Grid item xs={1}>
								<ScenarioBtn onClick={contentLeft}><ChevronLeft /></ScenarioBtn>
							</Grid>
							<Grid item xs={10}>
								<Typography className={classes.heading}>
									{contentHeads[content]}
								</Typography>
							</Grid>
							<Grid item xs={1}>
								<ScenarioBtn onClick={contentRight}><ChevronRight /></ScenarioBtn>
							</Grid>
						</Grid>
						<Grid container alignItems="center" justify="center" spacing={0}>
							{content === 0 ? <MetadataTable {...page.props.modelRunState.metadata}></MetadataTable> : null}
							{content === 1 ? <Grid container alignItems="center" justify="center"><Grid><Button variant="contained" endIcon={<CloudDownloadIcon />} onClick={downloadFiles}>{"Download " + page.activeLayer + " Data"}</Button></Grid></Grid> : null}
						</Grid>
					</Grid>
				</div> : 'No Metadata Loaded'
			}
		</StyledMapConfigDisplay>
	);
}

const mapStateToProps = state => {
	return {
		appState: state.appState,
		modelRunState: state.modelRunState,
	}
};

const dispatchToProps = (dispatch, props) => ({
});

export default connect(mapStateToProps, dispatchToProps)(ModelRunCard);
