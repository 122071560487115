import { types } from './../../actions/types';

const initialState = {
	sliders: [],
	dams: [],
	selectedDam: {},
	damStructures: [],
	viewport: { 
		style: "mapbox://styles/mapbox/light-v9",
		mapboxAccessToken: "pk.eyJ1IjoiYmlndmlraW5nbWoiLCJhIjoiY2sxMHhhenN4MGFuZzNkb2l3dHNwd2YybyJ9.gQoQyxLUPVXhuWYd6SeKlQ",
	},
	layers: [ {} ],
	damsAgg: [{}],
	damStats: {},
	geojson: { features: [], type: "FeatureCollection" },
	map: {},
	searchText: '',
	run: {},
	damPoints: { features: [], type: "FeatureCollection" },
	storage_pct: {},
	height_pct: {},
	popup: { visible: false, coordinates: [-96.0, 37.8], nid: "Nid", damName: "Name" },
}

export default (state=initialState , action) => {
    switch(action.type){
        case types.GET_GEOJSON:
			return {
				...state,
				geojson: action.payload.geojson,
			};
        case types.GET_DAM_STATS:
			return {
				...state,
				damStats: action.payload.damStats,
			};
        case types.GET_DAM_POINTS:
			return {
				...state,
				damPoints: action.payload,
			};
		case types.GET_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom
				},
			}
		case types.UPDATE_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom
				},
			}
		case types.GET_DAMS:
			return {
				...state,
				dams: action.payload,
			}
		case types.GET_RUN:
			return {
				...state,
				run: action.payload,
			}
		case types.GET_SLIDERS:
			  const rangeSliders = [{
				  title: 'Dam Height (Percentile)',
				  min: 0,
				  max: 100,
				  vals: [0, 100],
			},{
				  title: 'Storage Volume (Percentile)',
				  min: 0,
				  max: 100,
				  vals: [0, 100],
			}];

			return {
				...state,
				sliders: rangeSliders,
				storage_pct: action.payload.storage_pct.values,
				height_pct: action.payload.height_pct.values,
			}
		case types.UPDATE_SLIDER_VALS:
			const sliders = state.sliders.map(s => {
				if (s.title === action.payload.title) {
					s.vals = action.payload.vals;
				}
				return s;
			});
			return {
				...state, sliders,
			}
		case types.UPDATE_RUN:
			return {
				...state,
				run: action.payload,
			}
		case types.UPDATE_HEIGHTS:
			const heightSliders = state.sliders.map((slider) => {
				if (slider.title.indexOf('Height') !== -1) {
					slider.vals = action.payload;
				}
				return slider;
			});
			return {
				...state,
				sliders: heightSliders
			}
		case types.UPDATE_STORAGE:
			const storageSliders = state.sliders.map((slider) => {
				if (slider.title.indexOf('Storage') !== -1) {
					slider.vals = action.payload;
				}
				return slider;
			});
			return {
				...state,
				sliders: storageSliders
			}
		case types.UPDATE_HEATMAP:
			return {
				...state
			}
		case types.UPDATE_MAP:
			return {
				...state,
				map: action.payload
			}
		case types.UPDATE_SEARCH_TEXT:
			return {
				...state,
				searchText: action.payload
			}
		case types.UPDATE_SELECTED_DAM:
			return {
				...state,
				selectedDam: action.payload,
			}
		case types.UPDATE_DAM_STRUCTURES:
			return {
				...state,
				damStructures: action.payload,
			}
		case types.UPDATE_DAM_POPUP:
			return {
				...state,
				popup: {
					visible: action.payload.visible,
					coordinates: action.payload.coordinates,
					nid: action.payload.nid,
					damName: action.payload.damName,
				}
			}
		default:
			return state;
	}
};
