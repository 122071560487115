import React from 'react';
import './styles.scss';
// import Logo from '../../assets/graphics/logo.png';
import TemporaryDrawer from '../side-drawer';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { authContext } from '../../adalConfig';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}));

const Header = (props) => {
	const classes = useStyles();

	const getUserName = (userProfile) => {
		if (userProfile.given_name) {
			return userProfile.given_name;
		} else if (userProfile.name.lastIndexOf(',') !== -1) {
			return userProfile.name
				.split(',')[1]
				.split(' ')[1];
		} else if (userProfile.name.lastIndexOf('.') !== -1) {
			return userProfile.name
				.split('.')[0];
		} else if (userProfile.name.lastIndexOf(' ') !== -1) {
			return userProfile.name
				.split(' ')[0];
		} 
		return(' ');
	}

    return (
		<AppBar position="static" style={{background: '#3897A1'}} data-test="headerComponent">
			<Toolbar>
				<TemporaryDrawer />
				<Typography variant="h6" className={classes.title}>
					{ props.title }
				</Typography>
				<Typography variant="h6" className={classes.title} align="right" edge="end">
					Hello, {getUserName(authContext._user.profile)}
				</Typography>
			</Toolbar>
		</AppBar>
    )
};

export default Header;
