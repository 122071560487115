import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: {
  },
  sliderTitle: {
  	textAlign: 'center',
    // marginBottom: '30px',
  },
});

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState([props.min, props.max]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatValue = (value) => {
	  if ((value/1000000) > 10) {
		  return (value/1000000).toFixed(0) + "M";
	  } else if ((value/1000) > 10) {
		  return (value/1000).toFixed(0) + "k";
	  }
	  return value;
  }

  //const sliderCommit = (evt) => console.log(evt.target.id);

  return (
    <div className={classes.root}>
	  <Grid 
		  container
		  direction="row"
		  justify="center"
		  alignItems="center"
	  >
		  <Grid item xs={10}>
			  <Typography id="range-slider" className={classes.sliderTitle} gutterBottom>
				{props.title} 
			  </Typography>
		  </Grid>
		  <Grid item xs={10}>
			  <Slider
				id={props.title}
				min={props.min}
				max={props.max}
				value={value}
				onChange={handleChange}
                onChangeCommitted={props.updateSlider}
				valueLabelFormat={formatValue}
				valueLabelDisplay="auto"
				aria-labelledby="range-slider"
				getAriaValueText={valuetext}
			  />
	  </Grid>
	  </Grid>
    </div>
  );
}
