import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles({
	root: {
		paddingTop: '2px',
		paddingBottom: '2px',
	},
	listItemText: {
		fontSize: '10px',
	},
	icon: {
		float: 'right',
		fontSize: 'small',
	},
	linkIcon: {
		fontSize: 'small',
	}
});

const ResultsList = (props) => {
	const classes = useStyles();

	return(
		<div>
			<List component="nav" aria-label="Dam Result List">
					{props.dams.length > 0 ? props.dams.map((res, idx) => {
					return(
						<ListItem 
							key={idx} 
							className={classes.root}
							button
							onClick={() => props.onDamSelect(res)}>
							<ListItemText className={classes.listItemText} primary={
								<Typography className={classes.listItemText}>{res._source.dam_name}</Typography>}>
							</ListItemText>
							<ListItemIcon className={classes.icon}>
								<OpenInNewIcon className={classes.linkIcon}/>
							</ListItemIcon>
						</ListItem>
					);
				}) : null}
			</List>
		</div>
	);
}

export default ResultsList;
