import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportIcon from '@material-ui/icons/Report';
// import WebMapView from '../components/webmapview';

class ConstructionPage extends Component {
	render() {
		return (
			<div className="construction" style={{height: '100%'}} >
				<p style={{ textAlign: 'center', fontSize: '30px', color: '#626262' }}>
					<ReportIcon style={{ fontSize: '60px', color: '#767676' }}></ReportIcon>
					<br />
                    Under Construction
                </p>
				{/* <WebMapView ></WebMapView> */}
			</div>
		);
	}
}

const mapStateToProps = state => { return { ...state } };
const dispatchToProps = (dispatch, props) => ({
	dispatch
});

export default connect(mapStateToProps, dispatchToProps)(ConstructionPage);
