import { types } from './../../actions/types';
import appconfig from './../../constants';

const initialState = {
	viewport: { 
		style: "mapbox://styles/mapbox/light-v9",
		mapboxAccessToken: appconfig.MAPBOX_TOKEN,
		width: '100vw',
		height: '100vh',
		latitude: 37.8,
		longitude: -96,
		zoom: 3.5,
	},
  heatmap: { features: [], type: "FeatureCollection" },
  points: { features: [], type: "FeatureCollection" },
  map: { },
	point: {lat: 37.8, lng: -96, },
	searchText: "",
	popup: { visible: false, coordinates: [-96.0, 37.8], city: "City", state: "ST", id: "00" },
	cities: [	],
}

export default (state=initialState , action) => {
	switch(action.type) {
		case types.GET_RSWAM_VIEWPORT:
			return {
				...state,
				viewport: state.viewport,
				map: {
					zoom: state.viewport.zoom
				},
			}
		case types.GET_RSWAM_HEATMAP:
			return {
				...state,
				heatmap: action.payload,
			}
		case types.GET_RSWAM_POINTS:
			return {
				...state,
				points: action.payload,
			}
		case types.GET_RSWAM_CITIES:
			return {
				...state,
				cities: action.payload,
			}
		case types.UPDATE_RSWAM_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom
				},
			}
		case types.UPDATE_RSWAM_MAP:
			return {
				...state,
				map: action.payload
			}
		case types.UPDATE_RSWAM_TEXT:
			return {
				...state,
				searchText: action.payload
			}
		case types.UPDATE_RSWAM_POPUP:
			return {
				...state,
				popup: {
					visible: action.payload.visible,
					coordinates: action.payload.coordinates,
					city: action.payload.city,
					state: action.payload.state,
					id: action.payload.id,
				}
			}
		case types.UPDATE_RSWAM_POINT:
			return {
					...state,
					point: action.payload
			}
		default:
			return state;
	}
};
