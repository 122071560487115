import React, { Component } from 'react';
import ReactMapboxGl, {Layer, Feature, Popup} from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/button';
import { 
	fetchGeoJson, 
	getDamPoints, 
	getViewport, 
	getSliders, 
	updateSliderVals,
	updateHeights,
	updateStorage,
	updateMap,
	getDams,
	updateViewport,
	updateSelectedDam,
	getSearchDams,
	updateSearchText,
	updateDamStructures,
	updateDamPopup,
} from '../actions';
import { heatmapLayer, viewport, baseLayers } from '../constants/mapbox-styles';
import MetaCard from '../components/meta-card';
import Card from '@material-ui/core/Card';

const styles = theme => ({
	base: {
		zIndex: '1000',
		position: 'absolute',
		top: '5em',
		right: '60px',
	},
	baseToggle: {
		float: 'right',
		padding: '5px',
	},
	matCard: {
		padding: '5px',
	},
	styleBtn: {
		display: 'block',
	},
	baseLayers: {
		minWidth: '30px',
		width: '60px',
		padding: '0',
    },
    baseImg: {
        width: '60px'
    },
	map: {
		position: 'fixed',
	},
});

const Map = ReactMapboxGl({accessToken: viewport.accessToken});
const _getZoomPrecision = (mapZoom) => mapZoom < 6 ? 4 : 7;
const _getGeohashQuery = (bounds, value, type) => {
	return({
		type: type, 
		query: {
			text: value, 
			bounds: bounds, 
			aggregations: { 
				grid: {
					geohash_grid: { 
						field: "geohash", 
						precision: 4 
					}
				} 
			}
		}
	});
};


class DambreakMap extends Component {

	async componentDidMount() {
		await this.props.getViewport();
		await this.props.fetchHeatmap(
			_getGeohashQuery(
				this.refs.map.state.map.getBounds(), 
				this.props.dambreakState.searchText, 
				'heatmap'), 
			_getZoomPrecision(this.props.dambreakState.map.zoom)
		);
		await this.props.getSliders(
			_getGeohashQuery(this.refs.map.state.map.getBounds(), 
				this.props.dambreakState.searchText, 'sliders')
		);
	}

	render() {
		const { classes } = this.props;
		const getBaseMap = (url) => {
			this.props.updateViewport({
				...this.props.dambreakState.viewport,
				style: url,
			});
		};

		const onStyleLoad = (map) => {
			const { onStyleLoad } = this.props;
			return onStyleLoad && onStyleLoad(map);
		};

		const updateMap = (_, event) => {
			const bounds = event.target.getBounds();
			const zoom = event.target.getZoom();

			this.props.updateMap(bounds, zoom);

			const queryP = _getGeohashQuery(bounds, this.props.dambreakState.searchText, 'point');
			querySliders(queryP, this.props.dambreakState);
			this.props.getDamPoints(queryP, _getZoomPrecision(zoom));

			const queryH = _getGeohashQuery(bounds, this.props.dambreakState.searchText, 'heatmap');
			querySliders(queryH, this.props.dambreakState);
			this.props.fetchHeatmap(queryH,	_getZoomPrecision(zoom));
		};

		const clickMap = (_, event) => {
			this.props.getDams({bounds: event.target.getBounds(), 
				text: this.props.dambreakState.searchText, 
				nearestPoint: event.lngLat});
		};

		const onDamSearch = (event) => {
			const bounds = this.props.dambreakState.map.bounds;
			const zoom = this.props.dambreakState.map.zoom;

			this.props.updateSearchText(event.target.value);
			this.props.getSearchDams({
				text: event.target.value, 
				bounds: bounds 
			});

			const queryP = _getGeohashQuery(bounds, event.target.value, 'point');
			querySliders(queryP, this.props.dambreakState);
			this.props.getDamPoints(queryP, _getZoomPrecision(zoom));

			const queryH = _getGeohashQuery(bounds, event.target.value, 'heatmap');
			querySliders(queryH, this.props.dambreakState);
			this.props.fetchHeatmap(queryH, _getZoomPrecision(zoom));
		};

		const onSliderChange = (event, newValue) => {
			this.props.updateSliderVals(event.srcElement.parentElement.id, newValue);

			const bounds = this.props.dambreakState.map.bounds;
			const zoom = this.props.dambreakState.map.zoom;

			const queryP = _getGeohashQuery(bounds, this.props.dambreakState.searchText, 'point');
			querySliders(queryP, this.props.dambreakState);
			overrideSliders(queryP, event.srcElement.parentElement.id, newValue);	// Override with new values so there is no lag
			this.props.getDamPoints(queryP, _getZoomPrecision(zoom));

			const queryH = _getGeohashQuery(bounds, this.props.dambreakState.searchText, 'heatmap')
			querySliders(queryH, this.props.dambreakState);
			overrideSliders(queryH, event.srcElement.parentElement.id, newValue);	// Override with new values so there is no lag
			this.props.fetchHeatmap(queryH, _getZoomPrecision(zoom));
		};

		const querySliders = (query, dambreakState) => {
			const minHeightIdx = dambreakState.sliders[0].vals[0].toFixed(1);
			query.query.minHeight = dambreakState.height_pct[minHeightIdx];
			const maxHeightIdx = dambreakState.sliders[0].vals[1].toFixed(1);
			query.query.maxHeight = dambreakState.height_pct[maxHeightIdx];
			const minStorageIdx = dambreakState.sliders[1].vals[0].toFixed(1);
			query.query.minStorage = dambreakState.storage_pct[minStorageIdx];
			const maxStorageIdx = dambreakState.sliders[1].vals[1].toFixed(1);
			query.query.maxStorage = dambreakState.storage_pct[maxStorageIdx];
		}

		const overrideSliders = (query, id, value) => {
			const min = value[0].toFixed(1);
			const max = value[1].toFixed(1);
			if (id.startsWith("Storage")) {
				query.query.minStorage = this.props.dambreakState.storage_pct[min];
				query.query.maxStorage = this.props.dambreakState.storage_pct[max];
			} else {
				query.query.minStorage = this.props.dambreakState.height_pct[min];
				query.query.maxStorage = this.props.dambreakState.height_pct[max];
			}
		}

		return (
			<div>
				<MetaCard 
					{...this.props} 
					onDamSearch={onDamSearch} 
					onSliderChange={onSliderChange} />
				<div className={classes.base}>
				<div className={classes.baseToggle}>
					<Card className={classes.matCard}>
						<div className={classes.styleBtn}>
							{baseLayers.map((base, idx) => {
								return(
									<Button key={idx} className={classes.baseLayers} onClick={() => getBaseMap(base.style)}>
									<img src={base.staticUrl} alt="mapboximg" className={classes.baseImg} />
								</Button>)
							})}
						</div>
					</Card>
				</div>
				</div>
				<div className={classes.map}>
			<Map
				style={this.props.dambreakState.viewport.style}
				ref="map"
				center={viewport.center}
				zoom={viewport.zoom}
				onClick={clickMap}
			 	onZoomEnd={updateMap}
			 	onDragEnd={updateMap}
				onStyleLoad={onStyleLoad}
				containerStyle={{
					height: '100vh',
					width: '100vw',
				}}
			>
				<Layer type="heatmap" paint={heatmapLayer.paint}>
						{this.props.dambreakState.geojson.features.map((el, idx) => {
							return (
								<Feature key={idx} coordinates={el.geometry.coordinates} />
							);
						})}
				</Layer>

				<Layer 
					type="circle"
					id="Points"
					paint={{ "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 2, 10, 10],
					"circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.0, 8, 1.0],
					"circle-color": "#b2182b"}}>

						{this.props.dambreakState.damPoints.features.map((el, idx) => {
							return (
								<Feature 
									key={idx} 
									coordinates={el.geometry.coordinates}
									properties={el.properties}
									onMouseEnter={(e) => {
										if (this.props.dambreakState.map.zoom > 7.1) {
											const props = e.features[0].properties;
											this.props.updatePopup(true, e.lngLat, props.nodeid, props.title);
										}
									}}
									onMouseLeave={(e) => {
										this.props.updatePopup(false, [-96.0, 37.8], "", "");
									}}
								/>
							);
						})}

				</Layer>

				{ this.props.dambreakState.popup.visible ?
					<Popup
						coordinates={this.props.dambreakState.popup.coordinates}
						>
						<div><span><b>{this.props.dambreakState.popup.nid}</b></span><br/>
						<span>{this.props.dambreakState.popup.damName}</span></div>
					</Popup> 
					: null }

			</Map>
			</div>
			</div>
		);
	}
};

const mapStateToProps = state => { return { 
	appState: state.appState,
	dambreakState: state.dambreakState,
}};


const dispatchToProps = (dispatch, props) => ({
	fetchHeatmap: (es, zoom) => dispatch(fetchGeoJson(es, zoom)),
	getDamPoints: (es, zoom) => dispatch(getDamPoints(es, zoom)),
	getViewport: () => dispatch(getViewport()),
	getSliders: (es) => dispatch(getSliders(es)),
	updateViewport: (viewport) => dispatch(updateViewport(viewport)),
	updateHeights: (evt, vals) => dispatch(updateHeights(evt, vals)),
	updateStorage: (vals) => dispatch(updateStorage(vals)),
	updateMap: (bounds, zoom) => dispatch(updateMap(bounds, zoom)),
	getDams: (query) => dispatch(getDams(query)),
	getSearchDams: (query) => dispatch(getSearchDams(query)),
	updateSelectedDam: (dam) => dispatch(updateSelectedDam(dam)),
	updateSearchText: (text) => dispatch(updateSearchText(text)),
	updateSliderVals: (title, vals) => dispatch(updateSliderVals(title, vals)),
	updateDamStructures: (structures) => dispatch(updateDamStructures(structures)),
	updatePopup: (visible, coordinates, nid, damName) => dispatch(updateDamPopup(visible, coordinates, nid, damName)),
});

export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(DambreakMap);
