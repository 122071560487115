import React, { Component } from 'react';
import ReactMapboxGl, { Layer, Feature, Popup } from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/button';

import {
	getModelTypes,
	getVisibility,
	getResources,
	getExplorerGeoJson,
	updateExplorerMap,
	updateExplorerViewport,
	updateExplorerPoint,
	updateExplorerText,
	updateVisibility,
	updateExplorerPopup,
} from '../actions';

import { heatmapLayers, viewport, baseLayers } from '../constants/mapbox-styles';
import Card from '@material-ui/core/Card';
import ExplorerCard from '../components/explorer-card';

const styles = theme => ({
	base: {
		zIndex: '1000',
		position: 'absolute',
		top: '5em',
		right: '60px',
	},
	baseToggle: {
		float: 'right',
		padding: '5px',
	},
	matCard: {
		padding: '5px',
	},
	styleBtn: {
		display: 'block',
	},
	baseLayers: {
		minWidth: '30px',
		width: '60px',
		padding: '0',
    },
    baseImg: {
        width: '60px'
    },
	map: {
		position: 'fixed',
	},
});

const Map = ReactMapboxGl({accessToken: viewport.accessToken});
//const _getZoomPrecision = (mapZoom) => mapZoom > 7 ? 7 : 4;
const _getZoomPrecision = (mapZoom) => {
	if (mapZoom < 4) return 4;
	if (mapZoom > 7) return 7;
	if (typeof mapZoom === "undefined") return 4;
	return mapZoom;
}

class ExplorerPage extends Component {

	async componentDidMount() {
		await this.props.getModelTypes();

		await this.props.getVisibility(this.props.explorerState.modelTypes);

		await this.props.getResources(
			this.props.explorerState.modelTypes,
			this.props.explorerState.point,
			this.props.explorerState.searchText);

		await this.props.fetchHeatmap(
			this.refs.map.state.map.getBounds(),
			this.props.explorerState.searchText,
			this.props.explorerState.modelTypes,
			_getZoomPrecision(this.props.explorerState.map.zoom));
	}

	render() {

		const { classes } = this.props;

		console.log(this.props)

		const getBaseMap = (url) => {
			this.props.updateViewport({
				...this.props.explorerState.viewport,
				style: url,
			});
		};

		const onStyleLoad = (map) => {
			const { onStyleLoad } = this.props;
			return onStyleLoad && onStyleLoad(map);
		};

		const updateMap = (_, event) => {
			const bounds = event.target.getBounds();
			const zoom = event.target.getZoom();
			this.props.updateMap(bounds, zoom)

			this.props.fetchHeatmap(
				this.refs.map.state.map.getBounds(),
				this.props.explorerState.searchText,
				this.props.explorerState.modelTypes,
				_getZoomPrecision(this.props.explorerState.map.zoom));
			};

		const clickMap = (_, event) => {
			this.props.updatePoint(event.lngLat);
			this.props.getResources(
				this.props.explorerState.modelTypes,
				event.lngLat,
				this.props.explorerState.searchText
			);
		};

		const onModelSearch = (event) => {
			this.props.updateText(event.target.value);

			this.props.getResources(
				this.props.explorerState.modelTypes,
				this.props.explorerState.point,
				event.target.value);

			this.props.fetchHeatmap(
				this.refs.map.state.map.getBounds(),
				event.target.value,
				this.props.explorerState.modelTypes,
				_getZoomPrecision(this.props.explorerState.map.zoom));
		};

		const onModelExpand = (modelType, visible) => {
			this.props.updateVisibility(modelType, visible);
		}

		const getMarkerPoint = () => {
			const pnt = this.props.explorerState.point
			return [pnt.lng, pnt.lat];
		}

		const getPointColor = (model) => {
			var index = heatmapLayers.findIndex((hm) => {return (hm.modelType === model)});
			if (index >= 0) return heatmapLayers[index].pointColor;
			return "#808080";
		}

		const getHeatmapPaint = (model) => {
			var visIndex = this.props.explorerState.visibility.findIndex((vis) => {return (vis.modelType === model)});
			if (visIndex >= 0) {
				if (this.props.explorerState.visibility[visIndex].visible) {
					var layerIndex = heatmapLayers.findIndex((hm) => {return (hm.modelType === model)});
					if (layerIndex >= 0) {
						return heatmapLayers[layerIndex].paint;
					}
				}
			}
			return { "heatmap-opacity": 0.0 };
		}

		const getCircleOpacity = (model) => {
			var index = this.props.explorerState.visibility.findIndex((vis) => {return (vis.modelType === model)});
			if (index >= 0) {
				if (this.props.explorerState.visibility[index].visible) {
					return ["interpolate", ["linear"], ["zoom"], 7, 0.0, 8, 1.0];
				}
			}
			return 0.0;
		}

		const isDotVisible = (model) => {
            var index = this.props.explorerState.visibility.findIndex((vis) => {return (vis.modelType === model)});
            if (index >= 0) return this.props.explorerState.visibility[index].visible;
            return false;
		}

		return (
			<div>
				<ExplorerCard
					{...this.props}
					onModelSearch={onModelSearch}
					onModelExpand={onModelExpand}
					getColor={getPointColor}
					/>
				<div className={classes.base}>
					<div className={classes.baseToggle}>
						<Card className={classes.matCard}>
							<div className={classes.styleBtn}>
								{baseLayers.map((base, idx) => {
									return(
										<Button key={idx} className={classes.baseLayers} onClick={() => getBaseMap(base.style)}>
											<img src={base.staticUrl} alt="mapboximg" className={classes.baseImg} />
										</Button>)
								})}
							</div>
						</Card>
					</div>
				</div>
				<div className={classes.map}>
					<Map
						style={this.props.explorerState.viewport.style}
						ref="map"
						center={viewport.center}
						zoom={viewport.zoom}
						onClick={clickMap}
						onZoomEnd={updateMap}
						onDragEnd={updateMap}
						onStyleLoad={onStyleLoad}
						containerStyle={{
							height: '100vh',
							width: '100vw',
						}}>

						{this.props.explorerState.modelGeos.map((geo, index) => {
							return (
								<Layer
									key={index}
									type="heatmap"
									id={geo.modelType + " Heatmap"}
									paint={getHeatmapPaint(geo.modelType)}>
									{geo.heatmap.features.map((el, idx) => {
										return (<Feature key={idx} coordinates={el.geometry.coordinates} />);
									})}
								</Layer>
							);
						})};

						{this.props.explorerState.modelGeos.map((geo, index) => {
							return (
								<Layer
								key={index}
								type='circle'
								id={geo.modelType + " Points"}
								paint={{ "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 2, 10, 10],
										 "circle-opacity": getCircleOpacity(geo.modelType),
										 "circle-color": getPointColor(geo.modelType)}}>
									{geo.points.features.map((el, idx) => {
										return (
											<Feature
												key={idx}
												coordinates={el.geometry.coordinates}
												properties={el.properties}
												onMouseEnter={(e) => {
													if (this.props.explorerState.map.zoom > 7.1) {
														const props = e.features[0].properties;
														if (isDotVisible(props.model_type)) {
															this.props.updatePopup(true, e.lngLat, props.model_type, props.title);
														}
													}
												}}
												onMouseLeave={(e) => {
													this.props.updatePopup(false, [-96.0, 37.8], "", "");
												}}
											/>
										);
									})}
								</Layer>
							);
						})};

						<Layer
							type="circle"
							id="marker"
							paint={{ "circle-radius": 8, "circle-color": "#000000" }}>
							<Feature coordinates={getMarkerPoint()}/>
						</Layer>

						{ this.props.explorerState.popup.visible ?
							<Popup
								coordinates={this.props.explorerState.popup.coordinates}
								>
								<div><span><b>{this.props.explorerState.popup.modelType}</b></span><br/>
								<span>{this.props.explorerState.popup.model}</span></div>
							</Popup>
							: null }
					</Map>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => { return {
	appState: state.appState,
	explorerState: state.explorerState,
}};

const dispatchToProps = (dispatch, props) => ({
	getModelTypes: () => dispatch(getModelTypes()),
	getVisibility: (modelTypes) => dispatch(getVisibility(modelTypes)),
	getResources: (modelTypes, point, searchText) => dispatch(getResources(modelTypes, point, searchText)),
	updateViewport: (viewport) => dispatch(updateExplorerViewport(viewport)),
	updateMap: (bounds, zoom) => dispatch(updateExplorerMap(bounds, zoom)),
	updatePoint: (lngLat) => dispatch(updateExplorerPoint(lngLat)),
	updateText: (text) => dispatch(updateExplorerText(text)),
	updateVisibility: (modelType, visible) => dispatch(updateVisibility(modelType, visible)),
	fetchHeatmap: (bounds, value, modelTypes, zoomPrecision) => dispatch(getExplorerGeoJson(bounds, value, modelTypes, zoomPrecision)),
	updatePopup: (visible, coordinates, modelType, model) => dispatch(updateExplorerPopup(visible, coordinates, modelType, model)),
});

export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(ExplorerPage);
