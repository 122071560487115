import { types } from './types';

import {
	boolQuery,
	requestBodySearch,
	GeoHashGridAggregation,
} from 'elastic-builder';

import { 
	generateHeatmapGeojson,
	generateBoolQueryList,
	getEsQueryRswam,
	cleanElasticResponse,
	getMultiSearchQuery,
}  from '../utils';

export const getRSwamViewport = () => {
	return {
		type: types.GET_RSWAM_VIEWPORT,
		payload: "",
	}
}

export const getRSwamHeatmap = (es, zoomPrecision) => async(dispatch) => {
	const queries = generateBoolQueryList(es.query);
	const geo = new GeoHashGridAggregation('grid', 'geohash').precision(zoomPrecision);
	const queryBody = requestBodySearch()
		.query(boolQuery().must(queries))
		.agg(geo);

	await getEsQueryRswam(queryBody)
    .then(res => {
			processResults(res, es.type, dispatch);
    })
    .catch(err => {
      console.log(err);
    });
};

export const getRSwamPoints = (es, zoomPrecision) => async(dispatch) => {
	const queries = generateBoolQueryList(es.query);
	const queryBody = requestBodySearch()
		.query(boolQuery().must(queries));
	const numPoints = zoomPrecision >= 7 ? 5000 : 5;
	await getEsQueryRswam(queryBody, numPoints).then(res => {
		processResults(res.data.hits.hits, es.type, dispatch);
	})
	.catch(err => {
		console.log(err);
	});
};


export const getRSwamCities = (point, searchText) => async(dispatch) => {
	let query = {
		text: searchText, 
		textField: "city",
		bounds: null,
		nearestPoint: point,
		noRange: true,
	};
	let queryBody = getMultiSearchQuery(query).source(["city", "state", "state_abbr", "geohash", "geojson_file"]);
	const numPoints = 5;
	await getEsQueryRswam(queryBody, numPoints).then(res => {
		const cities = res.data.hits.hits.map((hit) => {
			return {city: hit._source.city, state: hit._source.state_abbr, id: hit._source.geohash, geojson: hit._source.geojson_file};
		});
		return dispatch({
			type: types.GET_RSWAM_CITIES,
			payload: cities,
		});
	})
	.catch(err => {
		console.log(err);
	});
}

export const updateRSwamViewport = (viewport) => {
	return {
		type: types.UPDATE_RSWAM_VIEWPORT,
		payload: {...viewport}
	}
}

export const updateRSwamMap = (bounds, zoom) => {
	return {
		type: types.UPDATE_RSWAM_MAP,
		payload: {
			bounds: bounds,
			zoom: zoom
		}
	};
}

export const updateRSwamText = (searchText) => {
	return {
		type: types.UPDATE_RSWAM_TEXT,
		payload: searchText
	};
}

export const updateRSwamPopup = (visible, coordinates, city, state, id) => {
	return {
		type: types.UPDATE_RSWAM_POPUP,
		payload: {
			visible: visible,
			coordinates: coordinates,
			city: city,
			state: state,
			id: id,
		}
	}
}

export const updateRSwamPoint = (lngLat) => {
	return {
		type: types.UPDATE_RSWAM_POINT,
		payload: lngLat
	};
}

const processResults = (res, type, dispatch) => {
	switch(type) {
		case 'heatmap':
			return dispatch({
				type: types.GET_RSWAM_HEATMAP,
				payload: generateHeatmapGeojson(res.data.aggregations.grid.buckets),
			});
		case 'point':
			return dispatch({
				type: types.GET_RSWAM_POINTS,
				payload: cleanElasticResponse(res),
			});
		default:
			return dispatch({
				type: types.GET_ES_QUERY,
				payload: res.data
			});
	}
};
