import { types } from './types';
import {
  fetchRunById,
  getModelRunFiles,
} from '../utils'
import { processGeojson } from 'kepler.gl/processors';
import apiAxios from '../interceptors/subscription-header';
import { createAction } from 'redux-actions';

export const appInit = createAction(types.MAP_INIT);
export const setMapConfig = createAction(types.SET_MAP_CONFIG);

export const updateModelRunViewport = (viewport) => {
  return {
    type: types.UPDATE_MODELRUN_VIEWPORT,
    payload: { ...viewport }
  }
}

export const updateModelRunMap = (bounds, zoom) => {
  return {
    type: types.UPDATE_MODELRUN_MAP,
    payload: {
      bounds: bounds,
      zoom: zoom,
    }
  };
}

export const getModelRunInfo = (modelType, modelId) => async (dispatch) => {
  await fetchRunById(modelId)
    .then(res => {
      const data = res.data.data;
      let damId = "";
      let riverId = "";
      const damCheck = data.metadata.value.uniqueDamId.value;
      if (Array.isArray(damCheck)) {
        damId = damCheck[0].title.value;
      } else {
        const riverCheck = data.metadata.value.riverineGageId.value;
        if (typeof riverCheck !== "undefined") {
          riverId = riverCheck.title.value;
        }
      }
      //const nidid = res.data.data.metadata.value.uniqueDamId.value[0].title.value;
      const layers = data.mapLayers.value.map((m) => {
        return { id: m.id.raw, name: m.layerName.value };
      })
      return dispatch({
        type: types.GET_MODELRUN_INFO,
        payload: {
          modelType: modelType,
          modelId: modelId,
          mapLayers: layers,
          modelInfo: res.data.data,
          damId: damId,
          riverId: riverId,
        }
      });
    })
    .catch(err => {
      console.log(err);
    });
}

export const getRelatedModelRuns = (nidid) => async (dispatch) => {

}
/**
 * this method requests the combined_results.json
 * @param {string} url the string url for requesting CombinedResults.json
 * @returns {{type: string, url: string}}
 */
export function requestCombinedResults(url) {
  return {
    type: types.REQUEST_COMBINED_RESULTS,
    url
  };
}

/**
 * this method loads the response of the geojson
 * @param {string} url the string url for requesting geojson
 * @param {object} json the json object returned from the fetch call 
 * @returns {{type: string, url: string, geojsonResults: object, receivedAt: Date}}
 */
export function receiveCombinedResults(url, json) {
  return {
    type: types.RECEIVE_COMBINED_RESULTS,
    url,
    geojsonResults: _mapGeojsonFiles(json),
    metadata: _mapMetadata(json),
    receivedAt: Date.now()
  };
}

function _isTypeGeoJson(meta) {
  return meta['type'] === 'GeoJson'
}

function _mapToFetchObj(jsonData) {
  return {
    url: jsonData.readUrl,
    label: jsonData.label,
    id: jsonData.hash
  };
}

// NOTE: This was added because the json file changed casing.
const _getCaseSensitiveKey = (json, key) => {
  const values = []
  Object.keys(json).forEach(k => {
    if (k) {
      const lowerK = k.toLowerCase();
      const output = {};
      output[lowerK] = k;
      values.push(output)
    }
  });
  return values.filter(v => v[key])[0][key];
}

const _mapMetadata = (json) => {
  const metaprocess = _getCaseSensitiveKey(json, 'metaprocess');
  const modelhydrographparams = _getCaseSensitiveKey(json[metaprocess], 'modelhydrographparams');
  const damparameters = _getCaseSensitiveKey(json[metaprocess][modelhydrographparams], 'damparameters');
  return json[metaprocess][modelhydrographparams][damparameters];
}

function _mapGeojsonFiles(json) {
  const metaprocess = _getCaseSensitiveKey(json, 'metaprocess');
  const resultfiles = _getCaseSensitiveKey(json[metaprocess], 'resultfiles');
  // TODO: This needs to include a check to make sure it is the valid json object and return an error
  const jsonFiles = json[metaprocess][resultfiles].filter(_isTypeGeoJson);
  return jsonFiles.map(x => _mapToFetchObj(x));
}

export function fetchCombinedResults(url) {
  return function (dispatch) {
    // dispatch(requestCombinedResults(url))

    return apiAxios.get(url)
      .then(
        response => response.data,
        error => console.log('An error occured getting combined_results.json', error)
      )
      .then(json =>
        dispatch(receiveCombinedResults(url, json))
      ).catch(
        err => console.log(err)
      )
  }
}

function _createLayer(results) {
  return {
    datasets: {
      info: {
        label: results.label,
        id: results.id
      },
      data: results.geojson
    },
    option: {
      centerMap: true,
      readOnly: false
    }
  }
}

/**
 * this method processes the response geojson
 * @param {object} fetchObj an object used to pass into the addDataToMap
 * @param {blob} json a json response object 
 * @returns {{type: string, dataset: object, receivedAt: Date}}
 */
export function receiveGeoJson(fetchObj, json) {
  fetchObj.geojson = json;
  const layer = _createLayer(fetchObj);
  return {
    type: types.RECEIVE_GEOJSON,
    payload: {
      dataset: layer,
      receivedAt: Date.now()
    }
  };
}

export const fetchModelRunGeoJson = (fetchObj, data) => async (dispatch) => {
  if (data) {
    return await apiAxios.post(fetchObj.url, data)
      // .then(
      //   response => response.data.json(),
      //   error => console.log('An error occured getting the geojson', error)
      // )
      .then(json => {
        const data = processGeojson(json.data);
        return dispatch(receiveGeoJson(fetchObj, data));
      });
  } else {
    return await fetch(fetchObj.url)
      .then(
        response => response.json(),
        error => console.log('An error occured getting the geojson', error)
      )
      .then(json => {
        const data = processGeojson(json);
        return dispatch(receiveGeoJson(fetchObj, data));
      });
  }
}

export const getModelFiles = modelRunId => async (dispatch) => {
  await getModelRunFiles(modelRunId).then(res => {
    return dispatch({
      type: types.GET_MODELRUN_FILES,
      payload: res.data.hits.hits
    })
  })
  .catch(err => {
    console.log(err);
  });
}

export const fetchMetadata = (url, data) => async (dispatch) => {
  await apiAxios.post(url, data)
    .then( res => {
      return dispatch({
        type: types.GET_METADATA,
        payload: res.data
      });    
    })
    .catch( err => console.log(err));

}

export const clearMetadata = () => async (dispatch) => 
  dispatch({type: types.CLEAR_METADATA, payload: {}});