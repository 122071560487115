import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { updateHeights, updateStorage } from '../../actions';
import RangeSlider from '../slider';
import ResultsList from '../results-list';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getSliders } from '../../actions';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import apiAxios from 'axios'
import { apim_key } from '../../constants';

const useStyles = makeStyles({
  root: {
    minWidth: 300,
	maxWidth: 300,
    position: 'absolute',
    zIndex: '1000',
    marginTop: '20px',
    marginLeft: '20px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  divide: {
    margin: '20px 0',
  },
  search: {
    width: '90%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  results: {
	width: 'inherit',
  },
  selectLabel: {
	paddingTop: '8px',
	fontSize: '8px',
  },
  select: {
    width: '250px',
  },
  submit: {
	width: 'inherit',
	fontSize: 13,
	color: "#0000C0",
	fontStyle: "italic",
  },
});

function validateEmail(email) {
	var re = /\S+@\w+\.[a-z]+$/;
	var result = re.test(email);
	return result;
}

const MetaCard = (props) => {

	const classes = useStyles();
	
	const [searchText, setSearchText] = React.useState(props.dambreakState.searchText);
	const updateText = (event) => {
		setSearchText(event.target.value);
	}

  const [structure, setStructure] = React.useState("");

  const [capacity, setCapacity] = React.useState(100);
  const capacities = [
	{value: 100, label: "100%"},
	{value: 75, label: "75%"},
	{value: 50, label: "50%"}
  ];

  const [resolution, setResolution] = React.useState(30);
  const resolutions = [
	{value: 10, label: "10 m"},
	{value: 30, label: "30 m"},
	{value: 90, label: "90 m"}
  ];

  const [email, setEmail] = React.useState("");

  const [runState, setRunState] = React.useState("");

  const onDamSelect = (dam) => {
	setRunState("");
	props.updateSelectedDam(dam);
	const url = "https://nisac.azure-api.net/utilities/damapi/options/dam/search?nidstruct=" + dam._source.nidid;
	apiAxios.get(url)
	.then(
		response => {
			props.updateDamStructures(response.data);
			if (response.data.length > 0) {
				setStructure(response.data[0].uniqueId);
			}
		},
		error => {
			console.log('An error occurred', error);
		}
	);
  }

  const onRunSubmit = () => {
	const url = "https://nisac.azure-api.net/create/rift/scenarios/dam/execute";
	const data = {
		dam: {
			breachHeightPercentage: capacity,
			uniqueDamId: structure
		},
		maximumResolutionMeters: resolution,
		notificationEmail: email
	}; 
	const header = {"Ocp-Apim-Subscription-Key": apim_key};
	apiAxios.post(url, data, {headers: header})
	.then(
		response => {
			setRunState("Run Submitted");
		},
		error => {
			console.log('An error occurred', error);
		}
	);
  };

  const selectStructure = (event) => {
	setStructure(event.target.value);
  }
 
  const selectCapacity = (event) => {
	setCapacity(event.target.value);
  }

  const selectResolution = (event) => {
	setResolution(event.target.value);
  }

  const selectEmail = (event) => {
	setEmail(event.target.value);
  }

	const onKeyPress = (event) => {
		if (event.keyCode === 13) {
			props.onDamSearch(event);	// Trigger when user presses Enter
		}
	}

	return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container direction="row" justify="center">
					<TextField 
						className={classes.search} 
						id="searchbar" 
						value={searchText}
						onChange={updateText}
						onBlur={props.onDamSearch}
						onKeyDown={onKeyPress}
						label="Search" />
				</Grid>
        <Divider className={classes.divide} variant='middle' />
			{props.dambreakState.sliders.length > 1 ? 
					props.dambreakState.sliders.map((slider, idx) => {
						return(<RangeSlider 
							key={idx} 
							{...slider} 
							value={slider.vals}
							updateSlider={props.onSliderChange} />);
					}) : null
			}
			{ props.dambreakState.dams.length > 0 ?
				<div>
					<Divider className={classes.divide} variant='middle' />
					<Typography variant="h6" component="h6" className={classes.sliderTitle} >
						 Results:
					</Typography>
					<ResultsList dams={[...props.dambreakState.dams]} onDamSelect={onDamSelect} />
				</div> : null
			}

		{ props.dambreakState.selectedDam._source && runState === "" ?
			<div className={classes.results}>
				<Divider className={classes.divide} variant='middle' />
				<Typography variant="h6" component="h6" className={classes.sliderTitle}>
				 Run Selected:
				</Typography>
				<InputLabel id="dam-name-label" className={classes.selectLabel}>Dam Name</InputLabel>
				<Select 
					labelId="dam-name-label"
					className={classes.select}
					id="dam-name"
					value={structure}
					onChange={selectStructure}>
					{
						props.dambreakState.damStructures.map((ds, idx) => {
							return (<MenuItem
								key={idx}
								value={ds.uniqueId}>{ds.nidid + " - " + ds.damName}</MenuItem>);
						})
					}
				</Select>
				<InputLabel id="capacity-label" className={classes.selectLabel}>Capacity (%)</InputLabel>
				<Select 
					labelId="capacity-label"
					className={classes.select}
					id="capacity"
					value={capacity}
					onChange={selectCapacity}>
					{
						capacities.map((cap, idx) => {
							return(<MenuItem 
								key={idx} 
								value={cap.value}>{cap.label}</MenuItem>);
						})
					}
				</Select>
				<InputLabel id="resolution-label" className={classes.selectLabel}>Resolution</InputLabel>
				<Select 
					labelId="resolution-label"
					className={classes.select}
					id="resolution"
					value={resolution}
					onChange={selectResolution}>
					{
						resolutions.map((res, idx) => {
							return(<MenuItem 
								key={idx} 
								value={res.value}>{res.label}</MenuItem>);
						})
					}
				</Select>
				<TextField 
					className={classes.select} 
					id="email" 
					label="Email"
					value={email}
					onChange={selectEmail} />
			</div> : null
		}
		{ runState === "Run Submitted" ?
			<div className={classes.submit}>
				<Typography variant="h8" component="h8" className={classes.submit}>
					{props.dambreakState.selectedDam._source.dam_name} successfully submitted: Please select another dam above!
				</Typography>
			</div> : null
		}
      </CardContent>
		{ props.dambreakState.selectedDam._source ?
      <CardActions className={classes.cardActions}>
        <Button size="small" disabled={structure==="" || !validateEmail(email) || runState === "Run Submitted" } onClick={onRunSubmit}>Submit Run</Button>
      </CardActions>: null }
    </Card>
  );
}

const mapStateToProps = state => { return { 
	appState: state.appState,
	dambreakState: state.dambreakState,
}};

const dispatchToProps = (dispatch, props) => ({
	getSliders: () => dispatch(getSliders()),
	updateHeights: (evt, vals) => dispatch(updateHeights(evt, vals)),
	updateStorage: (evt, vals) => dispatch(updateStorage(evt, vals)),
});

export default connect(mapStateToProps, dispatchToProps)(MetaCard);
