import { types } from './../../actions/types';
import appconfig from './../../constants';
import KeplerGlSchema from 'kepler.gl/schemas';

// CONSTANTS
export const INIT = 'INIT';
export const SET_MAP_CONFIG = 'SET_MAP_CONFIG';

const initialState = {
	viewport: {
		style: "mapbox://styles/mapbox/light-v9",
        mapboxAccessToken: appconfig.MAPBOX_TOKEN,
        width: '100vw',
        height: '100vh',
        latitude: 37.8,
        longitude: -96,
        zoom: 3.5,
	},
	map: { },
	modelType: "",
	modelId: "",
	appName: 'beltrami',
	// metadata: {
	// 	nidInput: {
	// 		nidid: '',
	// 		damName: '',
	// 	}
	// },
}

export default (state=initialState , action) => {
    switch(action.type){
		case types.UPDATE_MODELRUN_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom,
				},
			}
		case types.UPDATE_MODELRUN_MAP:
			return {
				...state,
				map: action.payload,
			}
		case types.UPDATE_MODELRUN_MODEL:
			return {
				...state,
				modelType: action.payload.modelType,
				modelId: action.payload.modelId,
			}
        case types.RECEIVE_COMBINED_RESULTS:
			return {
				...state,
				geojsonResults: action.geojsonResults,
				metadata: action.metadata,
				sas: action.sas,
			}
        case types.RECEIVE_GEOJSON:
			return {
				...state,
				dataset: action.payload.dataset,
			}
        case types.SET_MAP_CONFIG:
			return {
				...state,
				mapConfig: KeplerGlSchema.getConfigToSave(action.payload),
			}
        case types.MAP_INIT:
			return {
				...state,
				loaded: true, 
			}
        case types.GET_MODELRUN_FILES:
			return {
				...state,
				modelFiles: action.payload, 
			}
        case types.GET_METADATA:
			return {
				...state,
				metadata: action.payload, 
			}
        case types.CLEAR_METADATA:
			return {
				...state,
				metadata: action.payload, 
			}
        default:
            return state;
    }
};
