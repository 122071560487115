import axios from 'axios';
import pnnlConfig from '../config/default';

const apiAxios = axios.create();

apiAxios.interceptors.request.use((config) => {
    config.headers['Ocp-Apim-Subscription-Key'] = pnnlConfig.SUBSCRIPTION;
    if (config.data) {
        config.headers['Ocp-Apim-Subscription-Key'] = pnnlConfig.BLOBDOWNLOADER;
    }
    return config;
});

export default apiAxios;