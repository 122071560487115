import { 
    AuthenticationContext,
    adalFetch,
    withAdalLogin 
} from 'react-adal';
import pnnlConfig from './config/default';
 
let redirectUri = pnnlConfig.REDIRECT_URI;

export const adalConfig = {
  tenant: 'HSBIP.onmicrosoft.com',
  clientId: '1b7abe20-8c96-486b-8108-96dd3cbcea18',
  redirectUri,
  resource: 'https://nisac.azure-api.net',
  endpoints: pnnlConfig.endpoints,
  cacheLocation: 'localStorage',
};
 
export const authContext = new AuthenticationContext(adalConfig);
 
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
 
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);