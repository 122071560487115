import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	root: {
		width: '100%',
		overflowX: 'auto',
	},
	table: {
		minWidth: 300,
	},
	tableHead: {
		padding: '8px',
		fontWeight: 'bold',
	},
	tableRow: {
		padding: '4px',
	},
});


const MetadataTable = (props) => {
	const classes = useStyles();

	let metaFields = [];
	if (document.location.pathname.includes('dambreak')) {
		metaFields = [
			"breachFailureTime", "breachHeightPercentage", "breachSideSlope",
			"damTopWidth", "duration", "reservoirDepth",
			"reservoirVolume", "sideSlopeMultiple", "termBreachElev", "termBreachWidth"];
	} else {
		metaFields = [
			"gageName", "levees", "peakFlow",
			"resolutionMeters", "returnPeriod", "twoYearFlow",
			"uniqueGageId"];
	}

	const _createRows = (meta) => {
		const metaData = [];
		metaFields.forEach((field) => {
			if (meta[field] !== 0.0) {
				if (field === 'damTopWidth') {
					metaData.push({ "name": 'Dam Length (ft)', "value": meta[field].toFixed(2) });	
				}
				else {
					metaData.push({ "name": field, "value": meta[field].toFixed(2) });
				}
			}
		});
		return metaData;
	}

	const _createModelRows = (meta) => {
		const metaData = [];
		metaFields.forEach((field) => {
			if (meta[field] !== 0.0) {
				metaData.push({ "name": field, "value": meta[field] });
			}
		});
		return metaData;
	}

	return (
		<Paper className={classes.root}>
			<Table className={classes.table} aria-label="metadata table">
				{/* <Paper style={{maxHeight: 300, overflow: 'auto'}}> */}
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHead}>Parameter</TableCell>
						<TableCell className={classes.tableHead}>Scenario</TableCell>
						{/* <TableCell>{props['orchestrationID']}</TableCell> */}
					</TableRow>
				</TableHead>
				<TableBody>
					{(document.location.pathname.includes('dambreak')) ?
						_createRows(props).map((row, idx) => (
						<TableRow key={idx}>
							<TableCell className={classes.tableRow}>
								{row.name
									.replace(/([A-Z])/g, ' $1')
									.replace(/^./, function (str) { return str.toUpperCase(); })}
							</TableCell>
							<TableCell align="right" className={classes.tableRow}>{row.value}</TableCell>
						</TableRow>
					)) : 
						_createModelRows(props).map((row, idx) => (
						<TableRow key={idx}>
							<TableCell className={classes.tableRow}>
								{row.name
									.replace(/([A-Z])/g, ' $1')
									.replace(/^./, function (str) { return str.toUpperCase(); })}
							</TableCell>
							<TableCell align="right" className={classes.tableRow}>{row.value}</TableCell>
						</TableRow>
					))
					}
				</TableBody>
				{/* </Paper> */}
			</Table>
		</Paper>
	);
}

export default MetadataTable;
