import { types } from './types';
// import axios from 'axios';
import appconfig from '../constants';
import {
	boolQuery,
	requestBodySearch,
	StatsAggregation,
	GeoHashGridAggregation,
	PercentilesAggregation,
	// multiMatchQuery,
} from 'elastic-builder';

import { 
	generateHeatmapGeojson,
	generateBoolQueryList,
	getEsQuery,
	generateNearestSort,
	cleanElasticResponse,
}  from '../utils';

// queryObject = {
// modelType:
// text: searchText
// topic: '',
// nearestPoint: null};

export const updateHeatmap = () => {};

// TODO: GET_DAM_AGG: 'getDamAgg',
// TODO: GET_LAYERS: 'getLayers',
// TODO: UPDATE_LAYER_GEOJSON: 'updateLayerGeojson',
// TODO: UPDATE_HEIGHTS: 'updateHeights',
// TODO: UPDATE_STORAGE: 'updateStorage',

export const getRun = () => {
	return (
		{
			type: types.GET_RUN,
			payload: {
				nidid: '',
				capacity: '100%',
				resolution: '30 m',
				email: 'test.email@email.com',
			}
		}
	);
};

export const getDamPoints = (es, zoomPrecision) => async(dispatch) => {
	const queries = generateBoolQueryList(es.query);
	const queryBody = requestBodySearch()
		.query(boolQuery().must(queries));
	const numPoints = zoomPrecision >= 7 ? 5000 : 5;
	await getEsQuery(queryBody, numPoints).then(res => {
		processResults(res.data.hits.hits, 'dampoints', dispatch);
	})
	.catch(err => {
		console.log(err);
	});
};

export const updateRun = (run) => {
	return (
		{
			type: types.UPDATE_RUN,
			payload: run
		}
	);
};

export const getDams = (queryObj) => async(dispatch) => {
	const queries = generateBoolQueryList(queryObj);
	const sortObject = generateNearestSort(queryObj);
	const query = requestBodySearch()
		.query(boolQuery().must(queries))
		.sort(sortObject);
	await getEsQuery(query, 5).then(res => {
			processResults(res.data.hits.hits, 'dams', dispatch);
		})
		.catch(err => {
			console.log(err);
		});
}

export const getSearchDams = (queryObj) => async(dispatch) => {
	const queries = generateBoolQueryList(queryObj);

	const query = requestBodySearch()
		.source([
			"nidid_struct",
			"nidid",
			"uniqueid",
			"dam_name",
			"geohash"
		])
		.query(boolQuery().must(queries));
	
	await getEsQuery(query, 5).then(res => {
			processResults(res.data.hits.hits, 'dams', dispatch);
		})
		.catch(err => {
			console.log(err);
		});
}


// TODO: GET_SLIDERS: 'getSliders',
export const getSliders = (es) => async(dispatch) => {
	const pcts = [...Array(101).keys()]; // Every integer from 0 to 100
	const storage_q = new StatsAggregation("storage_stats", "nid_storage");
	const height_q = new StatsAggregation("height_stats", "nid_height");
	const pctsto_q = new PercentilesAggregation("storage_pct", "nid_storage").percents(pcts);
	const pcthgt_q = new PercentilesAggregation("height_pct", "nid_height").percents(pcts);
	const queries = generateBoolQueryList(es.query);
	const queryBody = requestBodySearch()
		.query(boolQuery().must(queries))
		.aggs([storage_q, height_q, pctsto_q, pcthgt_q]);
    await getEsQuery(queryBody).then(res => {
		processResults(res, es.type, dispatch);
    })
    .catch(err => {
        console.log(err);
    });
}

const processResults = (res, type, dispatch) => {
	switch(type) {
		case 'heatmap':
			return dispatch({
				type: types.GET_GEOJSON,
				payload: {
					geojson: generateHeatmapGeojson(res.data.aggregations.grid.buckets)
								
				}
			});
		case 'stats':
			// GET_DAM_STATS: 'getDamStats',
			return dispatch({
				type: types.GET_DAM_STATS,
				payload: {
					damStats: res.data.aggregations
				}
			});
		case 'dams':
			// GET_DAMS: 'getDams',
			return dispatch({
				type: types.GET_DAMS,
				payload: res 
			});
		case 'dampoints':
			return dispatch({
				type: types.GET_DAM_POINTS,
				payload: cleanElasticResponse(res),
			});
		case 'sliders':
			return dispatch({
				type: types.GET_SLIDERS,
				payload: res.data.aggregations,
			});
		default:
			return dispatch({
				type: types.GET_ES_QUERY,
				payload: res.data
			});
	}
};

export const fetchGeoJson = (es, zoomPrecision) => async(dispatch) => {
	const queries = generateBoolQueryList(es.query);
	const geo = new GeoHashGridAggregation('grid', 'geohash').precision(zoomPrecision);
	const queryBody = requestBodySearch()
		.query(boolQuery().must(queries))
		.agg(geo);

	await getEsQuery(queryBody)
    .then(res => {
		processResults(res, es.type, dispatch);
    })
    .catch(err => {
        console.log(err);
    });
};

// GET_VIEWPORT: 'getViewport',
export const getViewport = () => {
	return {
		type: types.GET_VIEWPORT,
		payload: { 
				style: 'mapbox://styles/mapbox/light-v9',
				width: '100vw',
				height: '100vh',
				latitude: 37.8,
				longitude: -96,
				zoom: 3.5,
				mapboxAccessToken: appconfig.MAPBOX_TOKEN,
		}
	};
}

export const updateViewport = (viewport) => {
	return {
		type: types.UPDATE_VIEWPORT,
		payload: {...viewport}
	}
}

export const updateHeights = (event, vals) => {
	return {
		type: types.UPDATE_HEIGHTS,
		payload: vals
	};
}

export const updateStorage = (event, vals) => {
	return {
		type: types.UPDATE_STORAGE,
		payload: vals
	};
}

export const updateSelectedDam = (dam) => {
	return {
		type: types.UPDATE_SELECTED_DAM,
		payload: dam
	};
}

export const updateSearchText = (searchText) => {
	return {
		type: types.UPDATE_SEARCH_TEXT,
		payload: searchText
	};
}

export const updateSliderVals = (title, vals) => {
	return {
		type: types.UPDATE_SLIDER_VALS,
		payload: {
			title: title,
			vals: vals
		}
	};
}

export const updateMap = (bounds, zoom) => {
	return {
		type: types.UPDATE_MAP,
		payload: {
			bounds: bounds,
			zoom: zoom
		}
	};
}

export const updateDamStructures = (structures) => {
	return {
		type: types.UPDATE_DAM_STRUCTURES,
		payload: structures
	};
}

export const updateDamPopup = (visible, coordinates, nid, damName) => {
	return {
		type: types.UPDATE_DAM_POPUP,
		payload: {
			visible: visible,
			coordinates: coordinates,
			nid: nid,
			damName: damName,
		}
	}
}

