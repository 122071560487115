import { types } from './types';
import apiAxios from '../interceptors/subscription-header';
import {
	boolQuery,
	requestBodySearch,
} from 'elastic-builder';
import { 
	getStateCityQuery, 
	cleanElasticResponse,
	getEsQueryTreat 
} from '../utils';

export const getRSwamRunViewport = () => {
	return {
		type: types.GET_RSWAM_RUN_VIEWPORT,
		payload: "",
	}
}

export const getRSwamRunGeojson = (url, data) => async (dispatch) => {
  await apiAxios.post(url, data)
    .then( res => {
      return dispatch({
        type: types.GET_RSWAM_RUN_GEOJSON,
        payload: res.data
			});
		})
		.catch( err => {
			console.log(err);
		});
}

export const getRSwamRunTreatment = (state, city) => async (dispatch) => {
	let queries = getStateCityQuery(state, city);
	const queryBody = requestBodySearch()
	.query(boolQuery().must(queries))
	.source([ "State", "StateAbbr", "City", "Region", "Lat", "Lon", "PWSID", "Name", "TreatType", "TreatMethod", "geohash" ]);

	await getEsQueryTreat(queryBody, 5000)
	.then(res => {
		const filtered = res.data.hits.hits.filter((rec) => { 
			return rec._source.City.toUpperCase() === city.toUpperCase();
		});
		// const plants = filtered.map((record) => {
		// 	return record._source;
		// })
		return dispatch({
			type: types.GET_RSWAM_RUN_TREATMENT,
			payload: cleanElasticResponse(filtered)
		});
	})
	.catch(err => {
			console.log(err);
	});
}

export const updateRSwamRunViewport = (viewport) => {
	return {
		type: types.UPDATE_RSWAM_RUN_VIEWPORT,
		payload: {...viewport}
	}
}

export const updateRSwamRunMap = (bounds, zoom) => {
	return {
		type: types.UPDATE_RSWAM_RUN_MAP,
		payload: {
			bounds: bounds,
			zoom: zoom
		}
	};
}

export const updateRSwamRunCityBounds = (bounds) => {
	return {
		type: types.UPDATE_RSWAM_RUN_CITYBOUNDS,
		payload: {
			bounds: bounds
		}
	};
}

export const updateRSwamRunOper = (index, oper) => {
	return {
		type: types.UPDATE_RSWAM_RUN_OPER,
		payload: {
			index: index,
			oper: oper
		}
	};
}

export const updateRSwamRunSC = (state, city) => {
	return {
		type: types.UPDATE_RSWAM_RUN_SC,
		payload: {
			state: state,
			city: city
		}
	};
}

