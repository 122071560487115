import { types } from './../../actions/types';

const initialState = {
	models: {
		count: 0,
		models: [],
	},
	modelTypes: {modelTypes: []},
}

const catalogReducer = (state=initialState , action) => {
    switch(action.type){
        case types.GET_MODELS:
			return {
				...state,
				models: action.payload,
			};
        case types.GET_MODEL_TYPES:
			return {
				...state,
				modelTypes: action.payload,
			};
        default:
            return state;
    }
};

export default catalogReducer; 