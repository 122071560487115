import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  root: {
		minWidth: 300,
		maxWidth: 300,
		position: 'absolute',
		zIndex: '1000',
		marginTop: '20px',
		marginLeft: '20px'
  },
  divide: {
    margin: '20px',
  },
  search: {
    width: '90%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortLabel: {
		margin: 0,
		fontSize: '12px'
	},
	listItem: {
		paddingTop: '8px',
		paddingBottom: '0px',
	},
	listItemText: {
		margin: '0px',
		fontSize: '10px',
	},
	icon: {
		float: 'right',
		fontSize: 'small',
	},
	linkIcon: {
		fontSize: 'small',
	},
});

const RSwamResultCard = (props) => {

	const classes = useStyles();

	const [contingency, setContingency] = React.useState("");

	const selectContingency = (event) => {
		setContingency(event.target.value);
		props.onUpdateContingency(event.target.value);
	}

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography variant="h6" component="h6">
					Contingency
				</Typography>
				<Select 
					labelId="contingency-label"
					className={classes.select}
					id="contingency-id"
					value={contingency}
					onChange={selectContingency}>
					{
						(props.rswamResultState.results) ?
						props.rswamResultState.results.map((res, idx) => {
							return (<MenuItem
								key={idx}
								value={res.geojson}>{res.City + " - " + res.Contingencies}</MenuItem>);
						}) : null
					}
				</Select>
			</CardContent>
			<CardActions className={classes.cardActions}>
			</CardActions>
		</Card>
	);
}

const mapStateToProps = state => { return {
	appState: state.appState,
	rswamResultState: state.rswamResultState,
}};

const dispatchToProps = (dispatch, props) => ({
});

export default connect(mapStateToProps, dispatchToProps)(RSwamResultCard);
