import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	listItemText: {
		fontSize: '10px',
	},
	icon: {
		float: 'right',
		fontSize: 'small',
	},
	linkIcon: {
		fontSize: 'small',
	},
	small: {
		width: 15,
		height: 15,
	},
	avatarColor: {
		backgroundColor: props => props.backgroundColor,
	},
});

export default function ModelList(props) {
	const {root, listItemText, icon, linkIcon, small, avatarColor} = useStyles(props);
  
	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(!open);
		props.onModelExpand(props.modelType, !open);
	};

	return (
    <div className={`${root}`}>
		<Grid container direction="row" alignItems="center">
			<Grid item xs={1}>
				<Avatar className={`${small} ${avatarColor}`}> </Avatar>
			</Grid>
			<Grid item xs={9}>
				<Typography variant="body1">{props.modelType}</Typography>
			</Grid>
			<Grid item xs={2}>
				{open ? <ExpandLess onClick={handleClick} /> : <ExpandMore onClick={handleClick} />}
			</Grid>
		</Grid>
		<Collapse in={open} timeout="auto" unmountOnExit>
			<List component="nav" aria-label={props.modelType + "Model List"}>
					{props.models.length > 0 ? props.models.map((model, idx) => {
					return(
						<ListItem 
							key={idx} 
							className={`${root}`}
							button 
							onClick={() => props.onModelSelect(props.modelType, model)}>
							<ListItemText 
								className={`${listItemText}`} 
								primary={<Typography className={`${listItemText}`}>{model.title}</Typography>}
								>
							</ListItemText>
							<ListItemIcon className={`${icon}`}>
								<OpenInNewIcon className={`${linkIcon}`}/>
							</ListItemIcon>
						</ListItem>
					);
				}) : null}
			</List>
		</Collapse>
	</div>
	);
}
