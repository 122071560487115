const appconfig = {
	ES_HOST: 'https://elastic.howstuffbreaks.org/',
	ES_INDEX: 'dam3',
	ES_RUN_INDEX: 'run-listing',
	ES_MODEL_INDEX: 'model-runs',
	ES_DAM_INDEX: 'automated-sims',
	ES_RSWAM_INDEX: 'rswam-cities',
	ES_TREAT_INDEX: 'treatment-plants',
	ES_RESULT_INDEX: 'rswam-results',
	ES_CREDS: {
		username: 'nisac_user',
		password: 's3cure'
	},
	MAPBOX_TOKEN: 'pk.eyJ1IjoidmVyb25pY2FtYXlhIiwiYSI6ImNrcTJqZTYwYjA5ODEycHBncXA0eXN4YTcifQ.8hyKLO2r_-TauHZXeJEv5Q',
};

export const apim_key = "be3ce1cba53a4f5bb4f4a316bc984df4";

export default appconfig;
