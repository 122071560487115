import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles({
  root: {
		minWidth: 300,
		maxWidth: 300,
		position: 'absolute',
		zIndex: '1000',
		marginTop: '20px',
		marginLeft: '20px'
  },
  divide: {
    margin: '20px',
  },
  search: {
    width: '90%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortLabel: {
		margin: 0,
		fontSize: '12px'
	},
	listItem: {
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	listItemText: {
		margin: '0px',
		fontSize: '10px',
	},
	icon: {
		float: 'right',
		fontSize: 'small',
	},
	linkIcon: {
		fontSize: 'small',
	},
});

export const modelTypes = {
	"Dam Break": "dambreak",
	"Global Flu": "flu",
	"pSWAM": "pswam",
	"Riverine Model": "riverine",
	"Storm Surge": "surge",
}

const CityCard = (props) => {

	const classes = useStyles();

	const [searchText, setSearchText] = React.useState(props.rswamState.searchText);
	const updateText = (event) => {
		setSearchText(event.target.value);
	}

	const onCitySelect = (id, geojson, state, city) => {
	 	props.history.push("/rswam-run/?id=" + id + "&geojson=" + geojson + "&state=" + state + "&city=" + city);
	}

	const onKeyPress = (event) => {
		if (event.keyCode === 13) {
			props.onCitySearch(event);	// Trigger when user presses Enter
		}
	}

	return (
		<Card className={classes.root}>
			<CardContent>
				<Grid container direction="row" justify="center">
					<TextField 
						className={classes.search} 
						id="searchbar"
						value={searchText}
						onChange={updateText}
						onBlur={props.onCitySearch}
						onKeyDown={onKeyPress}
						label="Search"/>
				</Grid>
				<Divider className={classes.divide} variant='middle' />
				<Typography variant="h6" component="h6">
					Cities
				</Typography>
				<p className={classes.sortLabel}>
					Sorted by: distance
				</p>

				<List aria-label={"City List"}>
					{props.rswamState.cities.length > 0 ? props.rswamState.cities.map((city, idx) => {
						return(
							<ListItem
								key={idx} 
								className={classes.listItem}
								onClick={() => onCitySelect(city.id, city.geojson, city.state, city.city)}
							>
								<ListItemText 
									className={classes.listItemText} 
									primary={<Typography className={classes.listItemText}>{city.city + ", " + city.state}</Typography>}>
								</ListItemText>
								<ListItemIcon className={classes.icon}>
									<OpenInNewIcon className={classes.linkIcon}/>
								</ListItemIcon>
							</ListItem>
						);
					}) : null}
			</List>

			</CardContent>
			<CardActions className={classes.cardActions}>
			</CardActions>
		</Card>
	);
}

const mapStateToProps = state => { return { 
	appState: state.appState,
	rswamState: state.rswamState,
}};

const dispatchToProps = (dispatch, props) => ({
});

export default connect(mapStateToProps, dispatchToProps)(CityCard);
