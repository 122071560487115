import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Box from '@material-ui/core/Box';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
		minWidth: 300,
		maxWidth: 300,
		position: 'absolute',
		zIndex: '1000',
		marginTop: '20px',
		marginLeft: '20px'
  },
  divide: {
    margin: '20px',
  },
  search: {
    width: '90%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortLabel: {
		margin: 0,
		fontSize: '12px'
	},
	listItem: {
		paddingTop: '8px',
		paddingBottom: '0px',
	},
	listItemText: {
		margin: '0px',
		fontSize: '10px',
	},
	icon: {
		float: 'right',
		fontSize: 'small',
	},
	linkIcon: {
		fontSize: 'small',
	},
});

const RSwamRunCard = (props) => {

	const classes = useStyles();

	const resultsAvailable = () => {
		const city = props.rswamRunState.cityName.toUpperCase();
		if (city === "BOISE") return true;
		if (city === "GRESHAM") return true;
		if (city === "LAKE OSWEGO") return true;
		if (city === "SALEM") return true;
		return false;
	}

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography variant="h6" component="h6">
					Water Treatment Plants
				</Typography>
				<Paper style={{maxHeight: 350, overflow: 'auto'}}> 
					<List aria-label={"Plant List"}>
						{(props.rswamRunState.treatmentPlants.features) ? 
							props.rswamRunState.treatmentPlants.features.map((plant, idx) =>{
							return(
								<ListItem
									key={idx}
									className={classes.listItem}
								>
									<Grid container direction='column'>
										<Grid item>
											<ListItemText
												className={classes.listItemText}
												primary={<Typography className={classes.listItemText}>{plant.properties.title}</Typography>}>
											</ListItemText>
										</Grid>
										<Grid item>
											<FormControl>
												<FormControlLabel
													control={
														<Checkbox
															key={idx}
															id={idx.toString()}
															checked={plant.properties.operational}
															onChange={props.onCheckChange}
														/>
													}
													label={"Operational"}
												/>
											</FormControl>
										</Grid>
										<Grid item>
											<Divider/>
										</Grid>
									</Grid>
								</ListItem>
							)
						}) : null}
					</List>
				</Paper>
				<Divider/>
				<Grid container justify="center">
					<Grid item>
						<Box p={2}>
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								onClick={props.onRSwamRun}
								disabled={!resultsAvailable()}
								endIcon={<PlayArrowIcon />}
							>
								Run
							</Button>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions className={classes.cardActions}>
			</CardActions>
		</Card>
	);
}

const mapStateToProps = state => { return {
	appState: state.appState,
	rswamRunState: state.rswamRunState,
}};

const dispatchToProps = (dispatch, props) => ({
});

export default connect(mapStateToProps, dispatchToProps)(RSwamRunCard);
