import React, { Component } from 'react';
import ReactMapboxGl, {Layer, Feature, Popup} from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/button';
import { 
	getRSwamRunViewport,
	getRSwamRunGeojson,
	getRSwamRunTreatment,
	updateRSwamRunViewport,
	updateRSwamRunMap,
	updateRSwamRunCityBounds,
	updateRSwamRunOper,
	updateRSwamRunSC,
} from '../actions';
import { viewport, baseLayers } from '../constants/mapbox-styles';
import Card from '@material-ui/core/Card';
import queryString from 'query-string';
import pnnlConfig from '../config/default';
import geojsonExtent from '@mapbox/geojson-extent';
import RSwamRunCard from '../components/rswamrun-card';
import { launchRSwam } from '../utils';

const styles = theme => ({
	base: {
		zIndex: '1000',
		position: 'absolute',
		top: '5em',
		right: '60px',
	},
	baseToggle: {
		float: 'right',
		padding: '5px',
	},
	matCard: {
		padding: '5px',
	},
	styleBtn: {
		display: 'block',
	},
	baseLayers: {
		minWidth: '30px',
		width: '60px',
		padding: '0',
    },
    baseImg: {
        width: '60px'
    },
	map: {
		position: 'fixed',
	},
});

const Map = ReactMapboxGl({accessToken: viewport.accessToken});

class RSwamRunPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			popup: false,
			popupCoords: [-96.0, 33.6],
			popupTitle: "",
			popupDesc: ""
		};
	}

	async componentDidMount() {
		// Parse the input values
		const search = this.props.location.search;
		const values = queryString.parse(search);

		await this.props.updateStateCity(values.state, values.city);

		// Load the Geojson of the City
		this.API_URL = pnnlConfig.API_URI + "BlobDownloader/BlobDownloader";
		const data = {
			'blobUrl': values.geojson
		};
		await this.props.getGeojson(this.API_URL, data);

		await this.props.getViewport();

		// Zoom into City
		var bounds = geojsonExtent(this.props.rswamRunState.geojson);
		await this.props.updateCityBounds(bounds);

		// Get the Treatment Plant locations
		await this.props.getTreatment(values.state, values.city);
		// const stop = 0;
	}

	render() {
		const { classes } = this.props;
		const getBaseMap = (url) => {
			this.props.updateViewport({
				...this.props.rswamRunState.viewport,
				style: url,
			});
		};

		const onStyleLoad = (map) => {
			const { onStyleLoad } = this.props;
			return onStyleLoad && onStyleLoad(map);
		};

		const updateMap = (_, event) => {
			const bounds = event.target.getBounds();
			const zoom = event.target.getZoom();

			this.props.updateMap(bounds, zoom);
		};

		const updatePopup = (viz, coords, title, desc) => {
			this.setState({popup: viz});
			this.setState({popupCoords: coords});
			this.setState({popupTitle: title});
			this.setState({popupDesc: desc});
		}

		const onOperationalChange = (event, newValue) => {
			var index = event.target.id;
			if (index !== "") {
				this.props.updateOper(index, newValue);
			}
		}

		const onRSwamRun = (event) => {
	
			// Parse the input values
			const search = this.props.location.search;
			const values = queryString.parse(search);

			const rswamParam = {
				"city": values.city.toLowerCase(),
				"state": values.state.toLowerCase(),
				"version": 1,
				"update": {
						"models[name='Pump']/stateVectorFragAssetPoint/epanet/disabledReservoirPumps": ["160010102232", "160010102231", "160010003031"]
				}
			}	

			launchRSwam(rswamParam)
			.then(res => {
				const data = res.data;
			})
			.catch(err => {
				console.log(err);
			});

			this.props.history.push("/rswam-result/?id=" + values.id + "&state=" + values.state + "&city=" + values.city);
		}

		return (
			<div>
				<RSwamRunCard 
					{...this.props}
					onCheckChange={onOperationalChange}
					onRSwamRun={onRSwamRun}
				/>
				<div className={classes.base}>
					<div className={classes.baseToggle}>
						<Card className={classes.matCard}>
							<div className={classes.styleBtn}>
								{baseLayers.map((base, idx) => {
									return(
										<Button key={idx} className={classes.baseLayers} onClick={() => getBaseMap(base.style)}>
										<img src={base.staticUrl} alt="mapboximg" className={classes.baseImg} />
									</Button>)
								})}
							</div>
						</Card>
					</div>
				</div>
				<div className={classes.map}>
					<Map
						style={this.props.rswamRunState.viewport.style}
						ref="map"
						center={viewport.center}
						fitBounds={this.props.rswamRunState.cityBounds}
						onZoomEnd={updateMap}
						onDragEnd={updateMap}
						onStyleLoad={onStyleLoad}
						containerStyle={{
							height: '100vh',
							width: '100vw',
						}}
					>

						<Layer type="fill" paint={{"fill-color": "#228b22", "fill-opacity": 0.4}}>
								{this.props.rswamRunState.geojson.features.map((el, idx) => {
									return (
										<Feature key={idx} coordinates={el.geometry.coordinates} />
									);
								})}
						</Layer>
						<Layer 
							type="circle"
							id="TreatmentPoints"
							paint={{ "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 2, 10, 10],
								"circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.0, 8, 1.0],
								"circle-color": "#b2182b"}}
						>
							{/* {console.log(this.props.rswamRunState.treatmentPlants.features)} */}
							{(this.props.rswamRunState.treatmentPlants.features) ? 
								// console.log(this.props.rswamRunState.treatmentPlants)
								this.props.rswamRunState.treatmentPlants.features.map((el, idx) => {
								return (
									<Feature 
										key={idx}
										coordinates={el.geometry.coordinates} 
										properties={el.properties}
										onMouseEnter={(e) => {
											updatePopup(
												true, 
												e.lngLat, 
												e.feature.properties['city'],
												e.feature.properties['title']
												)
										}}
										onMouseLeave = {(e) => {
											updatePopup(false, [-96.0, 37.8], "");
										}}
									/>
								);
							})
							: null}
						</Layer>

						{ this.state.popup ? 
							<Popup
								coordinates={this.state.popupCoords}
								>
								<div><span><b>{this.state.popupTitle}</b></span><br/>
								<span>{this.state.popupDesc}</span></div>
							</Popup> 
							: null
						}
					</Map>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => { return { 
	appState: state.appState,
	rswamRunState: state.rswamRunState,
}};

const dispatchToProps = (dispatch, props) => ({
	getViewport: () => dispatch(getRSwamRunViewport()),
	getGeojson: (url, data) => dispatch(getRSwamRunGeojson(url, data)),
	getTreatment: (state, city) => dispatch(getRSwamRunTreatment(state, city)),
	updateViewport: (viewport) => dispatch(updateRSwamRunViewport(viewport)),
	updateMap: (bounds, zoom) => dispatch(updateRSwamRunMap(bounds, zoom)),
	updateCityBounds: (bounds) => dispatch(updateRSwamRunCityBounds(bounds)),
	updateOper: (index, oper) => dispatch(updateRSwamRunOper(index, oper)),
	updateStateCity: (state, city) => dispatch(updateRSwamRunSC(state, city)),
});

export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(RSwamRunPage);
