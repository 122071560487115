import React, { Component } from 'react';
import ReactMapboxGl, {Layer, Feature, Popup} from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/button';
import { 
	getRSwamViewport,
	getRSwamHeatmap,
	getRSwamPoints,
	getRSwamCities,
	updateRSwamViewport,
	updateRSwamMap,
	updateRSwamText,
	updateRSwamPopup,
	updateRSwamPoint,
} from '../actions';
import { heatmapLayer, viewport, baseLayers } from '../constants/mapbox-styles';
import Card from '@material-ui/core/Card';
import CityCard from '../components/city-card';

const styles = theme => ({
	base: {
		zIndex: '1000',
		position: 'absolute',
		top: '5em',
		right: '60px',
	},
	baseToggle: {
		float: 'right',
		padding: '5px',
	},
	matCard: {
		padding: '5px',
	},
	styleBtn: {
		display: 'block',
	},
	baseLayers: {
		minWidth: '30px',
		width: '60px',
		padding: '0',
    },
    baseImg: {
        width: '60px'
    },
	map: {
		position: 'fixed',
	},
});

const Map = ReactMapboxGl({accessToken: viewport.accessToken});
const _getZoomPrecision = (mapZoom) => mapZoom < 6 ? 4 : 7;
const _getGeohashQuery = (bounds, value, type) => {
	return({
		type: type, 
		query: {
			text: value, 
			textField: "city",
			bounds: bounds, 
			noRange: true,
			aggregations: { 
				grid: {
					geohash_grid: { 
						field: "geohash", 
						precision: 4 
					}
				} 
			}
		}
	});
};


class RSwamPage extends Component {

	async componentDidMount() {
		await this.props.getViewport();	// Give map a chance to update before fetching heat map
		await this.props.getHeatmap(
			_getGeohashQuery(
				this.refs.map.state.map.getBounds(), 
				this.props.rswamState.searchText, 
				'heatmap'), 
			_getZoomPrecision(this.props.rswamState.map.zoom)
		);
		await this.props.getCities(
			this.props.rswamState.point, 
			this.props.rswamState.searchText
		);
	}

	render() {
		const { classes } = this.props;
		const getBaseMap = (url) => {
			this.props.updateViewport({
				...this.props.rswamState.viewport,
				style: url,
			});
		};

		const onStyleLoad = (map) => {
			const { onStyleLoad } = this.props;
			return onStyleLoad && onStyleLoad(map);
		};

		const updateMap = (_, event) => {
			const bounds = event.target.getBounds();
			const zoom = event.target.getZoom();

			this.props.updateMap(bounds, zoom);

			const queryP = _getGeohashQuery(bounds, this.props.rswamState.searchText, 'point');
			this.props.getPoints(queryP, _getZoomPrecision(zoom));

			const queryH = _getGeohashQuery(bounds, this.props.rswamState.searchText, 'heatmap');
			this.props.getHeatmap(queryH,	_getZoomPrecision(zoom));
		};

		const clickMap = (_, event) => {
			this.props.updatePoint(event.lngLat);
			this.props.getCities(
				event.lngLat, 
				this.props.rswamState.searchText
			);
		};

		const onCitySearch = (event) => {
			this.props.updateText(event.target.value);

			this.props.getCities(
				this.props.rswamState.point, 
				event.target.value);

			const bounds = this.refs.map.state.map.getBounds();
			const zoom = this.props.rswamState.map.zoom;
					
			const queryP = _getGeohashQuery(bounds, event.target.value, 'point');
			this.props.getPoints(queryP, _getZoomPrecision(zoom));

			const queryH = _getGeohashQuery(bounds, event.target.value, 'heatmap');
			this.props.getHeatmap(queryH,	_getZoomPrecision(zoom));
		};

		const getMarkerPoint = () => {
			const pnt = this.props.rswamState.point
			return [pnt.lng, pnt.lat];
		}

		return (
			<div>
				<CityCard 
					{...this.props}
					onCitySearch={onCitySearch}
					/>
				<div className={classes.base}>
					<div className={classes.baseToggle}>
						<Card className={classes.matCard}>
							<div className={classes.styleBtn}>
								{baseLayers.map((base, idx) => {
									return(
										<Button key={idx} className={classes.baseLayers} onClick={() => getBaseMap(base.style)}>
										<img src={base.staticUrl} alt="mapboximg" className={classes.baseImg} />
									</Button>)
								})}
							</div>
						</Card>
					</div>
				</div>
				<div className={classes.map}>
					<Map
						style={this.props.rswamState.viewport.style}
						ref="map"
						center={viewport.center}
						zoom={viewport.zoom}
						onClick={clickMap}
						onZoomEnd={updateMap}
						onDragEnd={updateMap}
						onStyleLoad={onStyleLoad}
						containerStyle={{
							height: '100vh',
							width: '100vw',
						}}
					>
						<Layer type="heatmap" paint={heatmapLayer.paint}>
								{this.props.rswamState.heatmap.features.map((el, idx) => {
									return (
										<Feature key={idx} coordinates={el.geometry.coordinates} />
									);
								})}
						</Layer>

						<Layer 
							type="circle"
							id="Points"
							paint={{ "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 2, 10, 10],
							"circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.0, 8, 1.0],
							"circle-color": "#b2182b"}}>

								{this.props.rswamState.points.features.map((el, idx) => {
									return (
										<Feature 
											key={idx} 
											coordinates={el.geometry.coordinates}
											properties={el.properties}
											onMouseEnter={(e) => {
												if (this.props.rswamState.map.zoom > 7.1) {
													const props = e.features[0].properties;
													this.props.updatePopup(true, e.lngLat, props.city, props.state, props.nodeid);
												}
											}}
											onMouseLeave={(e) => {
												this.props.updatePopup(false, [-96.0, 37.8], "");
											}}
										/>
									);
								})}
						</Layer>
							
						<Layer
							type="circle"
							id="marker"
							paint={{ "circle-radius": 8, "circle-color": "#000000" }}>
							<Feature coordinates={getMarkerPoint()}/>
						</Layer>

						{ this.props.rswamState.popup.visible ?
							<Popup
								coordinates={this.props.rswamState.popup.coordinates}
								>
								<span>{this.props.rswamState.popup.city + ", " + this.props.rswamState.popup.state}</span>
							</Popup> 
							: null }

					</Map>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => { return { 
	appState: state.appState,
	rswamState: state.rswamState,
}};


const dispatchToProps = (dispatch, props) => ({
	getViewport: () => dispatch(getRSwamViewport()),
	getHeatmap: (es, zoom) => dispatch(getRSwamHeatmap(es, zoom)),
	getPoints: (es, zoom) => dispatch(getRSwamPoints(es, zoom)),
	updateViewport: (viewport) => dispatch(updateRSwamViewport(viewport)),
	updateMap: (bounds, zoom) => dispatch(updateRSwamMap(bounds, zoom)),
	updateText: (text) => dispatch(updateRSwamText(text)),
	updatePopup: (visible, coordinates, city, state, id) => dispatch(updateRSwamPopup(visible, coordinates, city, state, id)),
	updatePoint: (lngLat) => dispatch(updateRSwamPoint(lngLat)),
	getCities: (point, searchText) => dispatch(getRSwamCities(point, searchText)),
});

export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(RSwamPage);
