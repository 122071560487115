import React, { Component } from 'react';
import { connect } from 'react-redux';
import './home.scss';
import HomeCard from '../components/home-card';
import Grid from '@material-ui/core/Grid';

class HomePage extends Component {
	render() {
		const cards = [
			{
				title: 'Explore',
				desc: 'Search the repository of content created by NISAC analysts using model and simulation tools.',
				buttons: [
					{
						title: 'Map',
						callback: '/explorer',
					},
					{
						title: 'Catalog',
						callback: '/catalog',
					},
				]
			},
			{
				title: 'Dam Break',
				desc: 'Find dams from national inventory of dams, and run break simulations.',
				buttons: [
					{
						title: 'Model Runs',
						callback: '/dambreak',
					},
				]
			},
			{
				title: 'rSWAM',
				desc: 'Select a city and run an infrastructure analysis using its regional surface water availability model.',
				buttons: [
					{
						title: 'City Map',
						callback: '/rswam',
					},
				]
			},
		];
		return (
			<div className="home">
				<Grid container direction="row" justify="center" spacing={3}>
					{ cards.map((card, i) => {
						return(
							<Grid item key={i} xs={3}>
								<HomeCard {...card} />
							</Grid>
						)
					}) }
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => { return { ...state } };
const dispatchToProps = (dispatch, props) => ({
	dispatch
});

export default connect(mapStateToProps, dispatchToProps)(HomePage);
