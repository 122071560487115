import { types } from './../../actions/types';
import appconfig from './../../constants';

const initialState = {
	viewport: { 
		style: "mapbox://styles/mapbox/light-v9",
		mapboxAccessToken: appconfig.MAPBOX_TOKEN,
		width: '100vw',
		height: '100vh',
		latitude: 37.8,
		longitude: -96,
		zoom: [ 3.5 ],
	},
	map: { zoom: 3.5, },
	geojson: {"type": "FeatureCollection","features": []},
	cityBounds: [-125.0, 25.0, -65.0, 50.0],
	cityName: "",
	stateName: "",
	treatmentPlants: { },
}

export default (state=initialState , action) => {
	switch(action.type) {
		case types.GET_RSWAM_RUN_VIEWPORT:
			return {
				...state,
				viewport: state.viewport,
				map: {
					zoom: state.viewport.zoom
				},
			}
		case types.GET_RSWAM_RUN_GEOJSON:
			return {
				...state,
				geojson: action.payload
			}
		case types.GET_RSWAM_RUN_TREATMENT:
			return {
				...state,
				treatmentPlants: action.payload
			}
		case types.UPDATE_RSWAM_RUN_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom
				},
			}
		case types.UPDATE_RSWAM_RUN_MAP:
			return {
				...state,
				map: action.payload
			}
		case types.UPDATE_RSWAM_RUN_CITYBOUNDS:
			return {
				...state,
				cityBounds: action.payload.bounds
			}
		case types.UPDATE_RSWAM_RUN_OPER:
			const index = action.payload.index;
			const oper = action.payload.oper;
			const tp = state.treatmentPlants;
			tp.features[index].properties.operational = oper;
			return {
				...state,
				treatmentPlants: tp
			}
		case types.UPDATE_RSWAM_RUN_SC:
			return {
				...state,
				stateName: action.payload.state,
				cityName: action.payload.city,
			}
		default:
			return state;
	}
};
