import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PublicIcon from '@material-ui/icons/Public';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const useStyles = makeStyles({
	root: {
		// width: 275,
		// height: 275,
		backgroundColor: '#C5E1A5',
	},
	cardContent: {
		display: 'flex',
		flexWrap: 'wrap',
		// flex: '1 0 auto',
		alignItems: 'flex-end',
		justifyContent: 'center',
	},
	title: {
		width: '100%',
	},
	desc: {
		fontSize: 14,
		width: '50%',
	},
	cardIcon: {
		fontSize: 80,
	},
	actions: {
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 16,
	},
});

const HomeCard = (props) => {
	const classes = useStyles();

	const reroute = (event, newValue) => {
		props.history.push(newValue);
	}

	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardContent}>
				<Typography className={classes.title} variant="h4" component="h2">
					{props.title}
				</Typography>
				<Typography className={classes.desc} color="textSecondary">
					{props.desc}
				</Typography>
				<PublicIcon className={classes.cardIcon} />
			</CardContent>
			<CardActions className={classes.actions}>
				{props.buttons.map((btn, i) => 
					<Button key={i} size="small" color="primary" onClick={(e) => reroute(e, btn.callback)}>{btn.title}</Button>
				)}
			</CardActions>
		</Card>
	);
}

// export default HomeCard;

const mapStateToProps = state => { return { ...state } };
const dispatchToProps = (dispatch, props) => ({
	dispatch
});

export default compose( withRouter, connect(mapStateToProps, dispatchToProps))(HomeCard);
