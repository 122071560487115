import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import { someAction } from "./../redux/someReducer/actions";
import { setAppTitle } from './actions';

const HistoryListener = ({
    children,
    // MDTP
    setAppTitle,
}) => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const history = useHistory();
    // const dispatch = useDispatch();

    // locations listener
    useEffect(() => {
        setAppTitle();
        setIsFirstRender(false);

        return history.listen((location) => {
            setAppTitle();
        });
    }, [history]);

    // if is the first time render, show loading
    if (isFirstRender) {
        return <p>Loading...</p>;
    }

    return children;
};

const mapDispatchToProps = (dispatch) => ({
    setAppTitle: () => dispatch(setAppTitle()),
});

export default connect(null, mapDispatchToProps)(HistoryListener);