import { types } from './types';
import {
	requestBodySearch,
	TermsAggregation,
	GeoHashGridAggregation,
	boolQuery,
} from 'elastic-builder';
import { 
	getMultiSearchQuery,
	getEsQuery2,
	getEsQuery3,
	generateBoolQueryList,
	generateHeatmapGeojson,
	cleanElasticResponse,
}  from '../utils';

const damList = [
	"breach_height_percentage",
	"city",
	"completed",
	"dam_database_id",
	"dam_execution_id",
	"dam_length",
	"dam_name",
	"dam_unique_id",
	"file_year",
	"geohash",
	"latitude",
	"longitude",
	"nid_height",
	"nid_storage",
	"nidid",
	"nidid_struct",
	"normal_storage",
	"orchestration_id",
	"resolution_meters",
	"state",
	"uniqueid",
];

const otherList = [
	"title",
	"tag",
	"topic",
	"geohash",
	"nid",
	"changed_date",
	"model_type",
];

const modelList = [
	"model_run_id",
	"start_datetime",
	"end_datetime",
	"succeeded",
	"model_run_desc",
	"git_uid",
	"geohash",
	"model_run_name",
	"model_file_name",
	"model_file_storage",
	"model_file_type_id",
	"file_format",
	"file_usage",
	"model_type_name"
];

const processResults = (res, type, dispatch) => {
	switch(type) {
		case 'modeltypes':
			return dispatch({
				type: types.GET_EXPLORER_MODELTYPES,
				payload: {
					modelTypes: res
				}
			});
		case 'heatmap':
			return dispatch({
				type: types.GET_EXPLORER_GEOJSON,
				payload: {
					geojson: generateHeatmapGeojson(res.data.aggregations.grid.buckets),
					points: cleanElasticResponse(res.data.hits.hits)
				}
			});
		default:
			return dispatch({
				type: types.GET_ES_QUERY,
				payload: res.data
			});
	}
};

export const getModelTypes = () => async(dispatch) => {
    let aggre = new TermsAggregation('model_type', 'model_type.keyword');
    let queryBody = requestBodySearch()
        .source(['model_type'])
        .agg(aggre);

    await getEsQuery2(queryBody).then(res => {
    	processResults(res.data.aggregations.model_type.buckets, 'modeltypes', dispatch);
	})
	.catch(err => {
		console.log(err);
	});
}

export const getVisibility = (modelTypes) => async(dispatch) => {
	const vis = modelTypes.map((mt) => {
		return {modelType: mt, visible: true};
	});
	return dispatch({
		type: types.GET_EXPLORER_VISIBILITY,
		payload: vis
	});
}

export const getResources = (modelTypes, point, searchText) => async(dispatch) => {
	const resources = await collectResources(modelTypes, point, searchText);
	return dispatch({
		type: types.GET_EXPLORER_RESOURCES,
		payload: resources
	});
}

const collectResources = async(modelTypes, point, searchText) => {
	const resources = await Promise.all(modelTypes.map((modelType) => {
		if (modelType === "Dam Break") {
			return collectResourceDam(modelType, point, searchText);
		} else if (modelType === "Riverine Model") {
			return collectResourceModel(modelType, point, searchText);
		} else {
			return collectResource(modelType, point, searchText);
		}
	}));
	return resources;
}

const collectResource = (modelType, point, searchText) => new Promise(async(resolve, reject) => {
	let query = {
		modelType: modelType,
		text: searchText,
		textField: "model_run_name",
		topic: '',
		bounds: null,
		nearestPoint: point,
		noRange: true,
	};
	let queryBody = getMultiSearchQuery(query);
	await getEsQuery2(queryBody, 5).then(res => {
		let models = [];
		res.data.hits.hits.forEach((hit) => {
			models.push({
				"nid": hit._source.nid,
				"title": hit._source.title,
				"geohash": hit._source.geohash,
			});
		});
		return resolve({"modelType": modelType, "models": models});
	})
	.catch(err => {
		return reject(err);
	});
});

const collectResourceModel = (modelType, point, searchText) => new Promise(async(resolve, reject) => {
	let query = {
		modelType: modelType,
		text: searchText,
		textField: "model_run_name",
		topic: '',
		bounds: null,
		nearestPoint: point,
		noRange: true,
	};
	let queryBody = getMultiSearchQuery(query);
	await getEsQuery3(modelType, queryBody, 5).then(res => {
		let models = [];
		res.data.hits.hits.forEach((hit) => {
			models.push({
				"nid": hit._source.model_run_id,
				"title": hit._source.model_run_name,
				"orchestra": hit._source.model_run_id,
				"geohash": hit._source.geohash,
			});
		});
		return resolve({"modelType": modelType, "models": models});
	})
	.catch(err => {
		return reject(err);
	});
});

const collectResourceDam = (modelType, point, searchText) => new Promise(async(resolve, reject) => {
	let query = {
		text: searchText, 
		textField: "dam_name",
		bounds: null,
		nearestPoint: point,
		noRange: true,
	};
	let queryBody = getMultiSearchQuery(query).source(damList);
	await getEsQuery3(modelType, queryBody, 5).then(res => {
		let models = [];
		res.data.hits.hits.forEach((hit) => {
			models.push({
				"nid": hit._source.nidid,
				"title": hit._source.dam_name + " (" + hit._source.breach_height_percentage + ")",
				"orchestra": hit._source.orchestration_id,
				"geohash": hit._source.geohash,
			});
		})
		return resolve({"modelType": modelType, "models": models});
	})
	.catch(err => {
		return reject(err);
	});
})

export const updateExplorerViewport = (viewport) => {
	return {
		type: types.UPDATE_EXPLORER_VIEWPORT,
		payload: {...viewport}
	}
}

export const updateExplorerMap = (bounds, zoom) => {
	return {
		type: types.UPDATE_EXPLORER_MAP,
		payload: {
			bounds: bounds,
			zoom: zoom
		}
	};
}

export const updateExplorerPoint = (lngLat) => {
	return {
		type: types.UPDATE_EXPLORER_POINT,
		payload: lngLat
	};
}

export const updateExplorerText = (text) => {
	return {
		type: types.UPDATE_EXPLORER_TEXT,
		payload: text
	}
}

export const updateVisibility = (modelType, visible) => {
	return {
		type: types.UPDATE_EXPLORER_VISIBILITY,
		payload: {
			modelType: modelType,
			visible: visible
		}
	}
}

export const updateExplorerPopup = (visible, coordinates, modelType, model) => {
	return {
		type: types.UPDATE_EXPLORER_POPUP,
		payload: {
			visible: visible,
			coordinates: coordinates,
			modelType: modelType,
			model: model,
		}
	}
}

export const getExplorerGeoJson = (bounds, value, modelTypes, zoomPrecision) => async(dispatch) => {
	const allGeos = await collectGeoJsons(bounds, value, modelTypes, zoomPrecision);
	const filterGeos = allGeos.filter((geo) => { 
		return geo.points.length > 0;
	});
	const modelGeos = filterGeos.map((geo) => {
		return {
			"modelType": geo.modelType,
			"heatmap": generateHeatmapGeojson(geo.heatmap),
			"points": cleanElasticResponse(geo.points)
		};
	});
	return dispatch({
		type: types.GET_EXPLORER_MODELGEOS,
		payload: modelGeos
	});
}

const collectGeoJsons = async(bounds, value, modelTypes, zoomPrecision) => {
	const modelGeos = await Promise.all(modelTypes.map((modelType) => {
		return collectGeoJson(bounds, value, modelType, zoomPrecision);
	}));
	return modelGeos;
}

const collectGeoJson = (bounds, value, modelType, zoomPrecision) => new Promise(async (resolve, reject) => {
	const type = modelType === 'Dam Break' ? undefined : modelType;
	const textField = (modelType === 'Dam Break') ? "dam_name" : "model_run_name";
	const query = getGeoQuery(bounds, value, textField, type);
	const queries = generateBoolQueryList(query);
	const geo = new GeoHashGridAggregation('grid', 'geohash').precision(zoomPrecision);
	const maxPoints = zoomPrecision >= 7 ? 5000 : 5;	// Get all the points when zoomed in close, otherwise just get 5

	let sourceList = otherList;
	if (modelType === 'Dam Break') {
		sourceList = damList;
	} else if (modelType === 'Riverine Model') {
		sourceList = modelList;
	}

	const queryBody = requestBodySearch()
		.source(sourceList)
		.query(boolQuery().must(queries))
		.agg(geo);

	await getEsQuery3(modelType, queryBody, maxPoints).then(res => {
		let points = res.data.hits.hits;
		let heatmap = res.data.aggregations.grid.buckets;
		return resolve({"modelType": modelType, "points": points, "heatmap": heatmap});
	})
	.catch(err => {
		return reject(err);
	});
})

const getGeoQuery = (bounds, value, field, model) => {
	return({
		text: value, 
		textField: field,
		bounds: bounds,
		modelType: model,
		noRange: true,
		aggregations: { 
			grid: {
				geohash_grid: { 
					field: "geohash", 
					precision: 4 
				}
			} 
		}});
};
