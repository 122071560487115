import { types } from './../../actions/types';
import appconfig from './../../constants';

const initialState = {
	viewport: { 
		style: "mapbox://styles/mapbox/light-v9",
		mapboxAccessToken: appconfig.MAPBOX_TOKEN,
		width: '100vw',
		height: '100vh',
		latitude: 37.8,
		longitude: -96,
		zoom: [ 3.5 ],
	},
	map: { zoom: 3.5, },
	geojson: {"type": "FeatureCollection","features": []},
	cityBounds: [-125.0, 25.0, -65.0, 50.0],
	cityName: "",
	stateName: "",
	treatmentPlants: { features: [{"type":"Feature", "geometry":{}, "properties":{"operational":true}}], type: "FeatureCollection" },
	results: [{"State": "Idaho", "StateAbbr": "ID", "City": "Boise", "Contingencies": "ABC", "geojson": "https://xswam.blob.core.windows.net/rswam-runs/geojson/23b97e37-6626-4cb9-a02a-aec8f2f2f52c_ID_Boise_bg_ABC.geojson"}],
}

export default (state=initialState , action) => {
	switch(action.type) {
		case types.GET_RSWAM_RESULT_VIEWPORT:
			return {
				...state,
				viewport: state.viewport,
				map: {
					zoom: state.viewport.zoom
				},
			}
		case types.GET_RSWAM_RESULT_CONTINGENCIES:
			return {
				...state,
				results: action.payload
			}
		case types.GET_RSWAM_RESULT_GEOJSON:
			return {
				...state,
				geojson: action.payload
			}
		case types.GET_RSWAM_RESULT_TREATMENT:
			return {
				...state,
				treatmentPlants: action.payload
			}
		case types.UPDATE_RSWAM_RESULT_VIEWPORT:
			return {
				...state,
				viewport: action.payload,
				map: {
					zoom: action.payload.zoom
				},
			}
		case types.UPDATE_RSWAM_RESULT_MAP:
			return {
				...state,
				map: action.payload
			}
		case types.UPDATE_RSWAM_RESULT_CITYBOUNDS:
			return {
				...state,
				cityBounds: action.payload.bounds
			}
		default:
			return state;
	}
};
