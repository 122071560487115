export const damList = [
	"breach_height_percentage",
	"city",
	"completed",
	"dam_database_id",
	"dam_execution_id",
	"dam_length",
	"dam_name",
	"dam_unique_id",
	"file_year",
	"geohash",
	"latitude",
	"longitude",
	"nid_height",
	"nid_storage",
	"nidid",
	"nidid_struct",
	"normal_storage",
	"orchestration_id",
	"resolution_meters",
	"state",
	"uniqueid",
];

export const otherList = [
	"title",
	"tag",
	"topic",
	"geohash",
	"nid",
	"changed_date",
	"model_type",
];

export const modelList = [
	"model_run_id",
	"start_datetime",
	"end_datetime",
	"succeeded",
	"model_run_desc",
	"git_uid",
	"geohash",
	"model_run_name",
	"model_file_name",
	"model_file_storage",
	"model_file_type_id",
	"file_format",
	"file_usage",
	"model_type_name"
];