import React, { Component } from 'react';
import ReactMapboxGl, {Layer, Feature, Popup} from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/button';
import { 
	getRSwamResultViewport,
	getRSwamResultContingencies,
	getRSwamResultGeojson,
	getRSwamResultTreatment,
	updateRSwamResultViewport,
	updateRSwamResultMap,
	updateRSwamResultCityBounds,
} from '../actions';
import { viewport, baseLayers } from '../constants/mapbox-styles';
import Card from '@material-ui/core/Card';
import queryString from 'query-string';
import pnnlConfig from '../config/default';
import geojsonExtent from '@mapbox/geojson-extent';
import RSwamResultCard from '../components/rswamresult-card';

const styles = theme => ({
	base: {
		zIndex: '1000',
		position: 'absolute',
		top: '5em',
		right: '60px',
	},
	baseToggle: {
		float: 'right',
		padding: '5px',
	},
	matCard: {
		padding: '5px',
	},
	styleBtn: {
		display: 'block',
	},
	baseLayers: {
		minWidth: '30px',
		width: '60px',
		padding: '0',
    },
    baseImg: {
        width: '60px'
    },
	map: {
		position: 'fixed',
	},
});

const Map = ReactMapboxGl({accessToken: viewport.accessToken});

class RSwamResultPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			popup: false,
			popupCoords: [-96.0, 33.6],
			popupTitle: "",
			popupDesc: ""
		};
	}

	async componentDidMount() {
		// Parse the input values
		const search = this.props.location.search;
		const values = queryString.parse(search);

		// Load the contingencies for the city
		await this.props.getContingencies(values.state, values.city);

		// Load the Geojson of the City
		this.API_URL = pnnlConfig.API_URI + "BlobDownloader/BlobDownloader";
		const data = {
			'blobUrl': this.props.rswamResultState.results[0].geojson
		};
		await this.props.getGeojson(this.API_URL, data);

		await this.props.getViewport();

		// Zoom into City
		var bounds = geojsonExtent(this.props.rswamResultState.geojson);
		await this.props.updateCityBounds(bounds);

		// Get the Treatment Plant locations
		await this.props.getTreatment(values.state, values.city);
	}

	render() {
		const { classes } = this.props;
		const getBaseMap = (url) => {
			this.props.updateViewport({
				...this.props.rswamResultState.viewport,
				style: url,
			});
		};

		const onStyleLoad = (map) => {
			const { onStyleLoad } = this.props;
			return onStyleLoad && onStyleLoad(map);
		};

		const updateMap = (_, event) => {
			const bounds = event.target.getBounds();
			const zoom = event.target.getZoom();

			this.props.updateMap(bounds, zoom);
		};

		const updatePopup = (viz, coords, title, desc) => {
			this.setState({popup: viz});
			this.setState({popupCoords: coords});
			this.setState({popupTitle: title});
			this.setState({popupDesc: desc});
		}

		const onUpdateContingency = (contingency) => {
			this.API_URL = pnnlConfig.API_URI + "BlobDownloader/BlobDownloader";
			const data = {
				'blobUrl': contingency
			};
			this.props.getGeojson(this.API_URL, data);		
		}

		const getColor = (value) => {
			const pct = Number(value);
			const blue = Math.floor(pct / 100 * 255);
			const red = Math.floor((100 - pct) / 100 * 255);
			const color = "rgb(" + red + ", 0, " + blue + ")";
			return color;
		}

		return (
			<div>
				<RSwamResultCard 
					{...this.props}
					onUpdateContingency={onUpdateContingency} 
				/>
				<div className={classes.base}>
					<div className={classes.baseToggle}>
						<Card className={classes.matCard}>
							<div className={classes.styleBtn}>
								{baseLayers.map((base, idx) => {
									return(
										<Button key={idx} className={classes.baseLayers} onClick={() => getBaseMap(base.style)}>
										<img src={base.staticUrl} alt="mapboximg" className={classes.baseImg} />
									</Button>)
								})}
							</div>
						</Card>
					</div>
				</div>
				<div className={classes.map}>
					<Map
						style={this.props.rswamResultState.viewport.style}
						ref="map"
						center={viewport.center}
						fitBounds={this.props.rswamResultState.cityBounds}
						onZoomEnd={updateMap}
						onDragEnd={updateMap}
						onStyleLoad={onStyleLoad}
						containerStyle={{
							height: '100vh',
							width: '100vw',
						}}
					>

						{(this.props.rswamResultState.geojson.features) ? 
							this.props.rswamResultState.geojson.features.map((el, idx) => {
								return (
									<Layer 
										key={idx}
										type="fill" 
										paint={{"fill-color": getColor(el.properties.VALUE), "fill-opacity": 0.4}}
									>
										<Feature 
											key={idx} 
											coordinates={el.geometry.coordinates}
										/>
									</Layer>
								);
							})
						: null
						}

						<Layer 
							type="circle"
							id="TreatmentPoints"
							paint={{ "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 2, 10, 10],
								"circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0.0, 8, 1.0],
								"circle-color": "#b2182b"}}
						>
							{(this.props.rswamResultState.treatmentPlants.features) ? 
								this.props.rswamResultState.treatmentPlants.features.map((el, idx) => {
								return (
									<Feature 
										key={idx}
										coordinates={el.geometry.coordinates} 
										properties={el.properties}
										onMouseEnter={(e) => {
											console.log(e);
											updatePopup(
												true, 
												e.lngLat, 
												e.feature.properties['city'],
												e.feature.properties['title']
												)
										}}
										onMouseLeave = {(e) => {
											updatePopup(false, [-96.0, 37.8], "");
										}}
									/>
								);
							})
							: null}
						</Layer>

						{ this.state.popup ? 
							<Popup
								coordinates={this.state.popupCoords}
								>
								<div><span><b>{this.state.popupTitle}</b></span><br/>
								<span>{this.state.popupDesc}</span></div>
							</Popup> 
							: null
						}
					</Map>
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => { return { 
	appState: state.appState,
	rswamResultState: state.rswamResultState,
}};

const dispatchToProps = (dispatch, props) => ({
	getViewport: () => dispatch(getRSwamResultViewport()),
	getContingencies: (state, city) => dispatch(getRSwamResultContingencies(state, city)),
	getGeojson: (url, data) => dispatch(getRSwamResultGeojson(url, data)),
	getTreatment: (state, city) => dispatch(getRSwamResultTreatment(state, city)),
	updateViewport: (viewport) => dispatch(updateRSwamResultViewport(viewport)),
	updateMap: (bounds, zoom) => dispatch(updateRSwamResultMap(bounds, zoom)),
	updateCityBounds: (bounds) => dispatch(updateRSwamResultCityBounds(bounds)),
});

export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(RSwamResultPage);
