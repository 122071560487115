import { 
    createStore,
    applyMiddleware,
    compose } 
from 'redux';

import ReduxThunk from 'redux-thunk';
import RootReducer from './reducers';
import { enhanceReduxMiddleware } from 'kepler.gl/middleware';

// export const middlewares = [ReduxThunk];
const middlewares = enhanceReduxMiddleware([
    ReduxThunk
]);

export const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

const enhancers = [applyMiddleware(...middlewares)];

// export const store = createStoreWithMiddleware(RootReducer)
export const store = createStore(
    RootReducer,
    compose(...enhancers)
);