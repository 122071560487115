import { types } from './types';
import apiAxios from '../interceptors/subscription-header';
import {
	boolQuery,
	requestBodySearch,
} from 'elastic-builder';
import { 
	getStateCityQuery, 
	cleanElasticResponse,
	getEsQueryTreat, 
	getEsQueryResult
} from '../utils';

export const getRSwamResultViewport = () => {
	return {
		type: types.GET_RSWAM_RESULT_VIEWPORT,
		payload: "",
	}
}

export const getRSwamResultGeojson = (url, data) => async (dispatch) => {
  await apiAxios.post(url, data)
    .then( res => {
      return dispatch({
        type: types.GET_RSWAM_RESULT_GEOJSON,
        payload: res.data
			});
		})
		.catch( err => {
			console.log(err);
		});
}

export const getRSwamResultContingencies = (state, city) => async(dispatch) => {
	let queries = getStateCityQuery(state, city);
	const queryBody = requestBodySearch()
	.query(boolQuery().must(queries))
	.source(["State", "StateAbbr", "City", "Contingencies", "geojson"]);

	await getEsQueryResult(queryBody, 5000)
	.then(res => {
		const mapped = res.data.hits.hits.filter((rec) => { 
			return rec._source.City.toUpperCase() === city.toUpperCase();
		}).map(rec => {
			return {
				"City": rec._source.City,
				"StateAbbr": rec._source.StateAbbr,
				"State": rec._source.State,
				"Contingencies": rec._source.Contingencies,
				"geojson": rec._source.geojson
			};
		})
		return dispatch({
			type: types.GET_RSWAM_RESULT_CONTINGENCIES,
			payload: mapped
		});
	})
	.catch(err => {
		console.log(err);
	});
}

export const getRSwamResultTreatment = (state, city) => async (dispatch) => {
	let queries = getStateCityQuery(state, city);
	const queryBody = requestBodySearch()
	.query(boolQuery().must(queries))
	.source([ "State", "StateAbbr", "City", "Region", "Lat", "Lon", "PWSID", "Name", "TreatType", "TreatMethod", "geohash" ]);

	await getEsQueryTreat(queryBody, 5000)
	.then(res => {
		const filtered = res.data.hits.hits.filter((rec) => { 
			return rec._source.City.toUpperCase() === city.toUpperCase();
		});
		return dispatch({
			type: types.GET_RSWAM_RESULT_TREATMENT,
			payload: cleanElasticResponse(filtered)
		});
	})
	.catch(err => {
			console.log(err);
	});
}

export const updateRSwamResultViewport = (viewport) => {
	return {
		type: types.UPDATE_RSWAM_RESULT_VIEWPORT,
		payload: {...viewport}
	}
}

export const updateRSwamResultMap = (bounds, zoom) => {
	return {
		type: types.UPDATE_RSWAM_RESULT_MAP,
		payload: {
			bounds: bounds,
			zoom: zoom
		}
	};
}

export const updateRSwamResultCityBounds = (bounds) => {
	return {
		type: types.UPDATE_RSWAM_RESULT_CITYBOUNDS,
		payload: {
			bounds: bounds
		}
	};
}
