import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import './catalog-page.scss';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import CatalogTable from '../components/catalog-table';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import {
	getModelTypes,
	getModelsByText
} from '../actions';

const styles = theme => ({
	root: {
		height: '100vh',
		paddingLeft: '24px !important',
		paddingRight: '24px !important',
	},
	paper: {
		height: '100%',
		padding: theme.spacing(6),
		textAlign: 'center',
		marginTop: 16,
	},
	radioCard: {
		marginTop: 30,
	}
});

class CatalogPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			radioValue: 'Dam Break',
			selectText: '',
		};
	}

	async componentDidMount() {
		await this.props.getModelTypes();
		await this.props.getModelsByText(this.state.selectText, 1000, 0, this.state.radioValue);
	}

	render() {
		const { classes } = this.props;

		const handleChange = async(event) => {
			this.setState({ radioValue: event.target.value });
			await this.props.getModelsByText(this.state.selectText, 1000, 0, event.target.value);
		};

		const getRadio = (idx, value) => 
			<Radio
				key={idx}
				checked={this.state.radioValue === value}
				value={value}
				name="radio-button-model"
				inputProps={{'aria-label': value}}
			/>;
		
		const changeSearchText = async (event) => {
			this.setState({ selectText: event.target.value });
			await this.props.getModelsByText(event.target.value, 1000, 0, this.state.radioValue);
		}

		return (
			<div className={classes.root}>
				<Grid 
					container 
					direction="row" 
					justify="center"
					spacing={3}
				>
					<Grid item className={classes.item} xs={12} sm={4}>
						<Paper className={classes.paper} elevation={3}>
							<Card>
								<CardContent>
									<TextField
										className={classes.search}
										id="search-field"
										label="Search"
										onChange={changeSearchText}
										InputProps={{
											endAdornment: (
											<InputAdornment position="end">
												<SearchIcon />
											</InputAdornment>
											),
										}}
									/>
								</CardContent>
							</Card>
							<Card className={classes.radioCard}>
								<CardContent>
									<FormControl component="fieldset">
										<FormLabel component="legend">Model Types</FormLabel>
										<RadioGroup 
											value={this.state.radioValue}
											onChange={handleChange}
											aria-label="model-types">
											{
												this.props.catalogState.modelTypes.modelTypes.map((radio, idx) => {
													return <FormControlLabel 
														key={idx}
														value={radio.key}
														control={getRadio(idx, radio.key)}
														label={radio.key} />;
												})
											}
										</RadioGroup>
									</FormControl>
								</CardContent>
							</Card>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Paper className={classes.paper} elevation={3}>
							<CatalogTable 
								{...this.props}
								modelType={this.state.radioValue}
								models={this.props.catalogState.models}
								getModelsByText={this.props.getModelsByText}
							/>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => { return { 
	appState: state.appState,
	catalogState: state.catalogState,
} };

const dispatchToProps = (dispatch, props) => ({
	getModelsByText: (queryText, size, pageNum, modelType) => dispatch(getModelsByText(queryText, size, pageNum, modelType)),
	getModelTypes: () => dispatch(getModelTypes()),
});

// export default connect(mapStateToProps, dispatchToProps)(CatalogPage);
export default compose( withStyles(styles), connect(mapStateToProps, dispatchToProps))(CatalogPage);
